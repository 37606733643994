import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material'

import { DateTime, Interval, Settings } from 'luxon'
import { useEffect, useState } from 'react'
import { PublicMeetingsQuery } from 'src/gql/graphql'

const TIME_ZONES = [
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',
]

const MEETING_BUFFER_MINUTES = 15
const BUTTON_REFRESH_INTERVAL_MS = 60000

interface Props {
  variant: 'general' | 'specialized'
  publicMeeting: PublicMeetingsQuery['general'][0]
  joinMeeting: VoidFunction
}

export const PublicMeetingCard = ({
  variant = 'general',
  publicMeeting,
  joinMeeting,
}: Props) => {
  const { nextStartsAt, header, title, duration, id } = publicMeeting

  const [time, setTime] = useState(DateTime.local())

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(DateTime.local())
    }, BUTTON_REFRESH_INTERVAL_MS)
    return () => {
      clearInterval(interval)
    }
  }, [])

  if (!nextStartsAt) return null

  const isLive = Interval.after(
    nextStartsAt.minus({ minutes: MEETING_BUFFER_MINUTES }),
    {
      minutes: duration + MEETING_BUFFER_MINUTES,
    },
  ).contains(time)

  return (
    <Card
      sx={t => ({
        padding: t.spacing(4),
        width: 300,
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
      })}
      elevation={0}>
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: variant !== 'specialized' ? 0 : undefined,
        }}>
        <Typography
          py={t => t.spacing(0.5)}
          px={t => t.spacing(2)}
          borderRadius={t => t.spacing(2)}
          color={t => t.palette.primary.dark}
          bgcolor={t => t.palette.primary.light}
          variant="caption">
          {header}
        </Typography>
      </CardContent>
      {variant === 'specialized' && (
        <CardHeader sx={{ textAlign: 'center', p: 0 }} title={title} />
      )}
      <CardContent sx={{ flex: 1 }}>
        {TIME_ZONES.map(timeZone => {
          const isSystemZone = timeZone === Settings.defaultZone.name
          return (
            <Typography
              key={`${timeZone}-${id}`}
              textAlign="center"
              py={t => t.spacing(1)}
              variant="body1"
              fontWeight={isSystemZone ? '900' : '200'}
              color={t => (isSystemZone ? t.palette.primary.main : undefined)}>
              {nextStartsAt
                .setZone(timeZone)
                .toFormat('ha ZZZZZ')
                .split(' ')
                .slice(0, 2)
                .join(' ')}
            </Typography>
          )
        })}
      </CardContent>
      <CardActions sx={{ justifyContent: 'center', flexDirection: 'column' }}>
        <Button onClick={joinMeeting} variant="contained" disabled={!isLive}>
          Join via Zoom
        </Button>
      </CardActions>
    </Card>
  )
}
