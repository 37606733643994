import { useMutation } from '@apollo/client'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { ReactNode, useState } from 'react'
import VerificationInput from 'react-verification-input'
import { graphql } from 'src/gql'
import { PublicMeetingsQuery } from 'src/gql/graphql'

interface Props {
  selectedMeeting: PublicMeetingsQuery['general'][0] | null
  clearMeeting: () => void
}

const RequestVerificationMutation = graphql(`
  mutation RequestSigninVerificationCode(
    $input: RequestSMSVerificationCodeInput!
  ) {
    requestSigninVerificationCode(input: $input) {
      __typename
    }
  }
`)

const SignInMutation = graphql(`
  mutation SignInV2($input: SignInWithPhoneNumberInput!) {
    signInV2(input: $input) {
      success
      message
      code
      session {
        jwt
      }
    }
  }
`)

export const JoinMeetingMutation = graphql(`
  mutation JoinMeetingMutation($input: JoinMeetingInput!) {
    joinMeeting(input: $input) {
      success
    }
  }
`)

const formatPhoneNumberWithUSCountryCode = (phoneNumber: string) => {
  let sanitizedNumber = phoneNumber.replace(/[^A-Z0-9]/gi, '')
  if (!sanitizedNumber.startsWith('1')) sanitizedNumber = `1${sanitizedNumber}`

  return `+${sanitizedNumber}`
}

export const SignInModal = ({ selectedMeeting, clearMeeting }: Props) => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [verificationRequested, setVerificationRequested] = useState(false)
  const [requestVerification] = useMutation(RequestVerificationMutation)
  const [signIn] = useMutation(SignInMutation)
  const [joinMeeting] = useMutation(JoinMeetingMutation)
  const [error, setError] = useState<ReactNode | null>()

  const onClose = () => {
    clearMeeting()
    setVerificationRequested(false)
    setError(null)
  }

  const getVerificationCode = () =>
    requestVerification({
      variables: {
        input: { phoneNumber: formatPhoneNumberWithUSCountryCode(phoneNumber) },
      },
      onCompleted: () => setVerificationRequested(true),
      onError: () =>
        setError(
          'Oops. The phone number you entered is incorrect. Please try again.',
        ),
    })

  const onEnterValidation = (code: string) => {
    if (code.length === 4) {
      signIn({
        variables: {
          input: {
            phoneNumber: formatPhoneNumberWithUSCountryCode(phoneNumber),
            code,
          },
        },
        onCompleted: ({ signInV2: { success, message, code, session } }) => {
          if (!selectedMeeting) return
          const { url, id, nextStartsAt } = selectedMeeting
          if (success) {
            if (session?.jwt) {
              joinMeeting({
                variables: {
                  input: { publicMeetingId: id, date: nextStartsAt },
                },
                context: {
                  headers: {
                    Authorization: `Bearer ${session.jwt}`,
                  },
                },
              })
            }

            window.open(url, '_blank')
            onClose()
          } else {
            if (code === 404)
              setError(
                <>
                  <Typography>
                    Sorry, this phone number is not associated with an account.
                  </Typography>
                  <Link
                    href="https://www.weconnecthealth.io/free"
                    target="_blank">
                    Download the app to create an account
                  </Link>
                </>,
              )
            else setError(message)
          }
        },
      })
    }
  }

  return (
    <Dialog
      open={!!selectedMeeting}
      onClose={onClose}
      fullWidth
      maxWidth={'xs'}>
      <DialogTitle variant="h5">Want a checkin for joining?</DialogTitle>
      <DialogContent>
        <Stack spacing={4} mt={2} textAlign="center">
          <Typography variant="body1" align="justify">
            If you already have an account on the{' '}
            <Link href="https://www.weconnecthealth.io/free" target="_blank">
              WEconnect app
            </Link>
            , you can sign in below to have this meeting count towards your
            checkins.
          </Typography>
          <Typography variant="body1" align="justify">
            Or, you can always join meetings as a guest.
          </Typography>
          <TextField
            autoFocus
            required
            margin="dense"
            type="tel"
            fullWidth
            variant="outlined"
            placeholder="Enter your phone number"
            label="Phone number"
            onChange={e => {
              setError(null)
              setPhoneNumber(e.target.value)
              setVerificationRequested(false)
            }}
          />
          {verificationRequested && (
            <>
              <Typography>Enter the code we sent over SMS here:</Typography>
              <VerificationInput
                autoFocus
                length={4}
                classNames={{ character: 'character', container: 'container' }}
                placeholder="_"
                containerProps={{ justifyContent: 'center' }}
                onChange={code => {
                  setError(null)
                  onEnterValidation(code)
                }}
              />
            </>
          )}
          {error && <Typography>{error}</Typography>}
          {!verificationRequested ? (
            <Button
              onClick={getVerificationCode}
              color="primary"
              variant="contained"
              size="large"
              disabled={!phoneNumber}>
              Sign in
            </Button>
          ) : (
            <Button onClick={getVerificationCode}>Get a new code</Button>
          )}
          <Divider variant="middle">or</Divider>
          <Button
            onClick={() =>
              selectedMeeting && window.open(selectedMeeting.url, '_blank')
            }
            type="submit"
            color="secondary"
            variant="contained"
            size="large"
            fullWidth>
            Join as guest
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
