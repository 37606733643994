/* eslint-disable */
import * as types from './graphql';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n    query TestQuery {\n      groups {\n        __typename\n      }\n    }\n  ": types.TestQueryDocument,
    "\n  mutation RequestSigninVerificationCode(\n    $input: RequestSMSVerificationCodeInput!\n  ) {\n    requestSigninVerificationCode(input: $input) {\n      __typename\n    }\n  }\n": types.RequestSigninVerificationCodeDocument,
    "\n  mutation SignInV2($input: SignInWithPhoneNumberInput!) {\n    signInV2(input: $input) {\n      success\n      message\n      code\n      session {\n        jwt\n      }\n    }\n  }\n": types.SignInV2Document,
    "\n  mutation JoinMeetingMutation($input: JoinMeetingInput!) {\n    joinMeeting(input: $input) {\n      success\n    }\n  }\n": types.JoinMeetingMutationDocument,
    "\n      query PublicMeetings {\n        general: publicMeetings(type: General) {\n          id\n          duration\n          header\n          id\n          url\n          timeZone\n          title\n          nextStartsAt\n        }\n        specialized: publicMeetings(type: Specialized) {\n          id\n          duration\n          header\n          id\n          url\n          timeZone\n          title\n          nextStartsAt\n        }\n      }\n    ": types.PublicMeetingsDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query TestQuery {\n      groups {\n        __typename\n      }\n    }\n  "): (typeof documents)["\n    query TestQuery {\n      groups {\n        __typename\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RequestSigninVerificationCode(\n    $input: RequestSMSVerificationCodeInput!\n  ) {\n    requestSigninVerificationCode(input: $input) {\n      __typename\n    }\n  }\n"): (typeof documents)["\n  mutation RequestSigninVerificationCode(\n    $input: RequestSMSVerificationCodeInput!\n  ) {\n    requestSigninVerificationCode(input: $input) {\n      __typename\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SignInV2($input: SignInWithPhoneNumberInput!) {\n    signInV2(input: $input) {\n      success\n      message\n      code\n      session {\n        jwt\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SignInV2($input: SignInWithPhoneNumberInput!) {\n    signInV2(input: $input) {\n      success\n      message\n      code\n      session {\n        jwt\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation JoinMeetingMutation($input: JoinMeetingInput!) {\n    joinMeeting(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation JoinMeetingMutation($input: JoinMeetingInput!) {\n    joinMeeting(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query PublicMeetings {\n        general: publicMeetings(type: General) {\n          id\n          duration\n          header\n          id\n          url\n          timeZone\n          title\n          nextStartsAt\n        }\n        specialized: publicMeetings(type: Specialized) {\n          id\n          duration\n          header\n          id\n          url\n          timeZone\n          title\n          nextStartsAt\n        }\n      }\n    "): (typeof documents)["\n      query PublicMeetings {\n        general: publicMeetings(type: General) {\n          id\n          duration\n          header\n          id\n          url\n          timeZone\n          title\n          nextStartsAt\n        }\n        specialized: publicMeetings(type: Specialized) {\n          id\n          duration\n          header\n          id\n          url\n          timeZone\n          title\n          nextStartsAt\n        }\n      }\n    "];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;