import { ApolloProvider } from '@apollo/client'
import { Box, ThemeProvider } from '@mui/material'
import { client } from './apollo'
import { theme } from './theme'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { PublicMeetings } from 'src/screens/PublicMeetings.tsx'
import * as Sentry from '@sentry/react'

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter([
  {
    path: '/meetings',
    element: <PublicMeetings />,
  },
  {
    path: '*',
    element: <PublicMeetings />,
  },
])

const App = () => {
  return (
    <Sentry.ErrorBoundary>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <Box
            py={t => t.spacing(3)}
            bgcolor={t => t.palette.grey[400]}
            color={t => t.palette.primary.main}>
            <RouterProvider router={router} />
          </Box>
        </ThemeProvider>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  )
}

export default App
