import { CircularProgress, Stack } from '@mui/material'
import { PublicMeetingCard } from './PublicMeetingCard'
import { PublicMeetingsQuery } from 'src/gql/graphql'

interface Props {
  variant?: 'general' | 'specialized'
  loading: boolean
  meetings: PublicMeetingsQuery['general']
  selectMeeting: (meeting: PublicMeetingsQuery['general'][0] | null) => void
}

export const PublicMeetingsList = ({
  loading,
  meetings,
  selectMeeting,
  variant = 'general',
}: Props) => (
  <Stack
    justifyContent="center"
    direction="row"
    spacing={6}
    flexWrap="wrap"
    useFlexGap>
    {loading && <CircularProgress size={100} />}
    {[...meetings]
      .sort(
        (a, b) =>
          (a.nextStartsAt?.toMillis() ?? 0) - (b.nextStartsAt?.toMillis() ?? 0),
      )
      .map(meeting => (
        <PublicMeetingCard
          key={meeting.id}
          variant={variant}
          publicMeeting={meeting}
          joinMeeting={() => selectMeeting(meeting)}
        />
      ))}
  </Stack>
)
