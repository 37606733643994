/* eslint-disable */
import type { DateTime } from 'luxon'
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Scalar Date type */
  Date: { input: DateTime; output: DateTime; }
  /** Scalar DateTime type */
  DateTime: { input: DateTime; output: DateTime; }
};

export enum AccountStatus {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  GracePeriod = 'GRACE_PERIOD'
}

export type Achievement = {
  __typename?: 'Achievement';
  category: Category;
  distinctDayCheckinCount: Scalars['Int']['output'];
  level: Scalars['Int']['output'];
  memberId: Scalars['Int']['output'];
  nextGoal?: Maybe<Scalars['Int']['output']>;
};

export type ActivationCodeInput = {
  activationCode: Scalars['String']['input'];
  birthDate: Scalars['Date']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  wecWorksConnection?: InputMaybe<WecWorksConnectionType>;
};

export type ActiveStreaks = {
  __typename?: 'ActiveStreaks';
  best: Scalars['Int']['output'];
  current: CurrentStreak;
};

export type AddDashboardUserToChannelResponse = MutationResponse & {
  __typename?: 'AddDashboardUserToChannelResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type AppVersion = {
  __typename?: 'AppVersion';
  android: Scalars['String']['output'];
  ios: Scalars['String']['output'];
};

export type AssignPeerInput = {
  memberId: Scalars['Int']['input'];
  peerId: Scalars['Int']['input'];
};

export type AttendedOnlineMeeting = {
  __typename?: 'AttendedOnlineMeeting';
  attendedDate: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type Audit = {
  __typename?: 'Audit';
  createdAt: Scalars['DateTime']['output'];
  dashboardUser: DashboardUser;
  id: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['String']['output'];
};

export type AwayMessage = {
  __typename?: 'AwayMessage';
  active: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  text: Scalars['String']['output'];
};

export type AwayMessageInput = {
  active: Scalars['Boolean']['input'];
  text: Scalars['String']['input'];
};

export type Barc10 = {
  __typename?: 'BARC10';
  expiresAt: Scalars['DateTime']['output'];
  shouldShow: Scalars['Boolean']['output'];
  shouldShowCard: Scalars['Boolean']['output'];
};

export enum BhConcernsInput {
  No = 'No',
  NotCurrently = 'NotCurrently',
  PreferNotToAnswer = 'PreferNotToAnswer',
  Yes = 'Yes'
}

export type Barc10MutationResponse = MutationResponse & {
  __typename?: 'Barc10MutationResponse';
  barc10?: Maybe<Barc10Response>;
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type Barc10Question = {
  __typename?: 'Barc10Question';
  body: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export type Barc10Response = Barc10 | ValidationErrors;

export type Barc10ResponseInput = {
  questionId: Scalars['Int']['input'];
  response: Scalars['Int']['input'];
};

export type Barc10Survey = {
  __typename?: 'Barc10Survey';
  answers: Array<Barc10Answer>;
  date?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  score: Scalars['Int']['output'];
};

export type Barc10V3Input = {
  responses: Array<Barc10ResponseInput>;
};

export type BeginCheckoutInput = {
  birthDate: Scalars['DateTime']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type BeginCheckoutResponse = MutationResponse & {
  __typename?: 'BeginCheckoutResponse';
  checkout?: Maybe<Checkout>;
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type BehavioralHealthConcern = {
  __typename?: 'BehavioralHealthConcern';
  freeFormResponse?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type BehavioralHealthProfile = {
  __typename?: 'BehavioralHealthProfile';
  concerns?: Maybe<Array<BehavioralHealthConcern>>;
  daysInterfered?: Maybe<Scalars['Int']['output']>;
  experiencingConcerns?: Maybe<ExperiencingConcernsResponse>;
  id: Scalars['Int']['output'];
};

export type Brand = {
  __typename?: 'Brand';
  alwaysShowDisclaimer: Scalars['Boolean']['output'];
  disclaimer: Scalars['String']['output'];
  image: Scalars['String']['output'];
  items: Array<Item>;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CancelCheckoutResponse = MutationResponse & {
  __typename?: 'CancelCheckoutResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type Category = {
  __typename?: 'Category';
  active: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  groupId: Scalars['Int']['output'];
  groupName: CategoryGroup;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export enum CategoryGroup {
  Chores = 'Chores',
  /** @deprecated No longer supported */
  CourtAppointment = 'CourtAppointment',
  DeviceDowntime = 'DeviceDowntime',
  EatDrink = 'EatDrink',
  Exercise = 'Exercise',
  /** @deprecated No longer supported */
  Finance = 'Finance',
  /** @deprecated No longer supported */
  Gateway = 'Gateway',
  /** @deprecated No longer supported */
  GroupSession = 'GroupSession',
  GroupSupport = 'GroupSupport',
  HealthManagement = 'HealthManagement',
  Hobbies = 'Hobbies',
  /** @deprecated No longer supported */
  Housing = 'Housing',
  Hygiene = 'Hygiene',
  Job = 'Job',
  /** @deprecated No longer supported */
  MedicallyAssistedTreatment = 'MedicallyAssistedTreatment',
  Meditation = 'Meditation',
  /** @deprecated No longer supported */
  Meeting = 'Meeting',
  OneOnOne = 'OneOnOne',
  /** @deprecated No longer supported */
  OnlineMeeting = 'OnlineMeeting',
  Other = 'Other',
  ReadWrite = 'ReadWrite',
  ReligionSpirituality = 'ReligionSpirituality',
  School = 'School',
  Sleep = 'Sleep',
  SocialTime = 'SocialTime',
  /** @deprecated No longer supported */
  UrinalysisAppointment = 'UrinalysisAppointment',
  WEcOnlineMeetings = 'WEcOnlineMeetings',
  WEcPeerSupport = 'WEcPeerSupport'
}

export type Challenge = {
  __typename?: 'Challenge';
  amount: Scalars['Int']['output'];
  challengeName: Scalars['String']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  earnedProgress: Scalars['Int']['output'];
  earnedReward?: Maybe<EarnedReward>;
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  goalType: ChallengeGoalType;
  id: Scalars['Int']['output'];
  isTutorial: Scalars['Boolean']['output'];
  number: Scalars['Int']['output'];
  selfCareGoal: Scalars['Int']['output'];
  selfCareProgress: Scalars['Int']['output'];
  startsAt: Scalars['DateTime']['output'];
  supportGoal: Scalars['Int']['output'];
  supportProgress: Scalars['Int']['output'];
};

export enum ChallengeGoalType {
  RoutineCheckin = 'RoutineCheckin',
  RoutineCreation = 'RoutineCreation'
}

export type ChallengeStructure = {
  __typename?: 'ChallengeStructure';
  amount: Scalars['Int']['output'];
  duration?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  selfCareGoal: Scalars['Int']['output'];
  supportGoal: Scalars['Int']['output'];
};

export type ChangePasswordInput = {
  newPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type CheckMutationResponse = MutationResponse & {
  __typename?: 'CheckMutationResponse';
  checkin: CheckinResponse;
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type Checkin = {
  __typename?: 'Checkin';
  checkInAt?: Maybe<Scalars['DateTime']['output']>;
  checkOutAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['Date']['output'];
  id: Scalars['Int']['output'];
  location?: Maybe<CheckinLocation>;
  notPresentWarningSeen: Scalars['Boolean']['output'];
  routine: Routine;
  timeWarningSeen: Scalars['Boolean']['output'];
};

export type CheckinLocation = {
  __typename?: 'CheckinLocation';
  accuracy: Scalars['Float']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type CheckinLocationInput = {
  accuracy: Scalars['Float']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type CheckinResponse = Checkin | ValidationErrors;

export type Checkout = {
  __typename?: 'Checkout';
  customerId: Scalars['String']['output'];
  ephemeralKey?: Maybe<Scalars['String']['output']>;
  setupIntentClientSecret?: Maybe<Scalars['String']['output']>;
  setupIntentId?: Maybe<Scalars['String']['output']>;
};

export type ConcernInput = {
  concernId: Scalars['Int']['input'];
  freeFormResponse?: InputMaybe<Scalars['String']['input']>;
};

export type Contract = {
  __typename?: 'Contract';
  durationOfMessaging?: Maybe<Scalars['Int']['output']>;
  durationOfRewards?: Maybe<Scalars['Int']['output']>;
  eligibleMemberCount?: Maybe<Scalars['Int']['output']>;
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  enrollmentEndsAt?: Maybe<Scalars['Date']['output']>;
  enrollmentStartsAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  planYearEarningsLimit?: Maybe<Scalars['Int']['output']>;
  planYearStartsAt?: Maybe<Scalars['Date']['output']>;
  requiresReferral: Scalars['Boolean']['output'];
  rewardsPlan?: Maybe<RewardsPlan>;
  rewardsPlanId?: Maybe<Scalars['Int']['output']>;
  startingBalance: Scalars['Int']['output'];
  tangoBrandKeys: Array<Scalars['String']['output']>;
  treatmentProviders: Array<TreatmentProvider>;
  type?: Maybe<ContractType>;
};

export type ContractInput = {
  durationOfMessaging?: InputMaybe<Scalars['Int']['input']>;
  durationOfRewards?: InputMaybe<Scalars['Int']['input']>;
  eligibleMemberCount?: InputMaybe<Scalars['Int']['input']>;
  endsAt?: InputMaybe<Scalars['DateTime']['input']>;
  enrollmentEndsAt?: InputMaybe<Scalars['Date']['input']>;
  enrollmentStartsAt?: InputMaybe<Scalars['Date']['input']>;
  name: Scalars['String']['input'];
  planYearEarningsLimit?: InputMaybe<Scalars['Int']['input']>;
  planYearStartsAt?: InputMaybe<Scalars['Date']['input']>;
  requiresReferral: Scalars['Boolean']['input'];
  rewardsPlanId: Scalars['Int']['input'];
  startingBalance: Scalars['Int']['input'];
  type?: InputMaybe<ContractType>;
};

export type ContractMutationResponse = MutationResponse & {
  __typename?: 'ContractMutationResponse';
  code: Scalars['Int']['output'];
  contract?: Maybe<ContractResponse>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ContractResponse = Contract | ValidationErrors;

export enum ContractType {
  HealthPlan = 'HealthPlan',
  ServiceProvider = 'ServiceProvider',
  WecWorks = 'WecWorks'
}

export type CreateAccountMutationResponse = MutationResponse & {
  __typename?: 'CreateAccountMutationResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  session?: Maybe<SessionResponse>;
  success: Scalars['Boolean']['output'];
};

export type CreateAccountV5Input = {
  activationFields?: InputMaybe<ActivationCodeInput>;
  languageTag?: InputMaybe<Scalars['String']['input']>;
  preferredName?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBulkRoutineV2Input = {
  memberIds: Array<Scalars['Int']['input']>;
  routine: CreateRoutineV2Input;
};

export type CreateBulkRoutineV2Response = {
  __typename?: 'CreateBulkRoutineV2Response';
  createdRoutines: Array<Routine>;
  failedMemberIds: Array<Scalars['Int']['output']>;
};

export type CreateCheckinInput = {
  checkInAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  dateV2?: InputMaybe<Scalars['Date']['input']>;
  location?: InputMaybe<CheckinLocationInput>;
  notPresentWarningSeen?: InputMaybe<Scalars['Boolean']['input']>;
  routineId: Scalars['Int']['input'];
  timeWarningSeen?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateDashboardUserInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type CreateDashboardUserResponse = MutationResponse & {
  __typename?: 'CreateDashboardUserResponse';
  code: Scalars['Int']['output'];
  dashboardUser?: Maybe<DashboardUserResponse>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type CreateFeatureInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
};

export type CreateFeatureResponse = {
  __typename?: 'CreateFeatureResponse';
  feature?: Maybe<Feature>;
};

export type CreateRedeemedRewardInput = {
  amount: Scalars['Int']['input'];
  utid: Scalars['String']['input'];
};

export type CreateRewardsCodeInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  contractId: Scalars['Int']['input'];
  distributionMethod?: InputMaybe<DistributionMethod>;
  redemptionLimit: Scalars['Int']['input'];
  startingBalance?: InputMaybe<Scalars['Int']['input']>;
  treatmentProviderId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateRewardsCodesInput = {
  contractId: Scalars['Int']['input'];
  count: Scalars['Int']['input'];
  distributionMethod?: InputMaybe<DistributionMethod>;
  redemptionLimit: Scalars['Int']['input'];
  startingBalance?: InputMaybe<Scalars['Int']['input']>;
  treatmentProviderId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateRewardsCodesResponse = MutationResponse & {
  __typename?: 'CreateRewardsCodesResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  rewardsCodes?: Maybe<Array<RewardsCode>>;
  success: Scalars['Boolean']['output'];
};

export type CreateRewardsPlanInput = {
  barc10Amount: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  segments: Array<RewardsPlanSegmentInput>;
  surveyAmount: Scalars['Int']['input'];
};

export type CreateRoutineV2Input = {
  anytime?: InputMaybe<Scalars['Boolean']['input']>;
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  dashboardUserId?: InputMaybe<Scalars['Int']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<RoutineLocationInput>;
  memberId?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  openaiId?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<RoutineOrigin>;
  publicMeetingId?: InputMaybe<Scalars['Int']['input']>;
  recurrence: Array<Scalars['String']['input']>;
  reminders?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  status?: InputMaybe<RoutineStatusType>;
  wellnessGoalIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type CreateSubscriptionInput = {
  priceId: Scalars['String']['input'];
  promoCodeId?: InputMaybe<Scalars['String']['input']>;
  setupIntentId: Scalars['String']['input'];
};

export type CreateTreatmentProviderInput = {
  contractId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type CurrentStreak = {
  __typename?: 'CurrentStreak';
  count: Scalars['Int']['output'];
  lastUpdated?: Maybe<Scalars['DateTime']['output']>;
};

export type DashboardNotification = {
  __typename?: 'DashboardNotification';
  createdAt: Scalars['DateTime']['output'];
  event: DashboardNotificationEvent;
  id: Scalars['Int']['output'];
  member: Member;
  memberId: Scalars['Int']['output'];
  seen: Scalars['Boolean']['output'];
};

export enum DashboardNotificationEvent {
  AccountDeactivated = 'AccountDeactivated',
  AccountDeleted = 'AccountDeleted',
  GoalCreated = 'GoalCreated',
  GoalDeleted = 'GoalDeleted',
  PaymentFailed = 'PaymentFailed',
  PeerAssigned = 'PeerAssigned',
  SubscriptionCanceled = 'SubscriptionCanceled'
}

export type DashboardUser = {
  __typename?: 'DashboardUser';
  active: Scalars['Boolean']['output'];
  activeMemberCount?: Maybe<Scalars['Int']['output']>;
  appDisplayName: Scalars['String']['output'];
  awayMessage?: Maybe<AwayMessage>;
  awayMessageActive?: Maybe<Scalars['Boolean']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  calendarLink?: Maybe<Scalars['String']['output']>;
  cumulativeEngagementDuration?: Maybe<Scalars['Int']['output']>;
  email: Scalars['String']['output'];
  engagedMemberCount?: Maybe<Scalars['Int']['output']>;
  /** @deprecated use engagementDurationLastNDays */
  engagementDurationLast30Days?: Maybe<Scalars['Int']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  inactiveMemberCount?: Maybe<Scalars['Int']['output']>;
  lastName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  profileImageUrl?: Maybe<Scalars['String']['output']>;
  pronouns?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Role>;
  timeZone?: Maybe<Scalars['String']['output']>;
  workHours?: Maybe<WorkHours>;
};


export type DashboardUserCumulativeEngagementDurationArgs = {
  end: Scalars['DateTime']['input'];
  start: Scalars['DateTime']['input'];
};

export type DashboardUserResponse = DashboardUser | ValidationErrors;

export type DeleteAccountInput = {
  _?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeleteAccountResponse = MutationResponse & {
  __typename?: 'DeleteAccountResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type DeleteCheckinInput = {
  id: Scalars['Int']['input'];
};

export type DeleteCheckinResponse = {
  __typename?: 'DeleteCheckinResponse';
  memberV2: Member;
};

export type DeleteDeviceInput = {
  deviceId: Scalars['String']['input'];
};

export type DeleteDeviceResponse = {
  __typename?: 'DeleteDeviceResponse';
  code: Scalars['Int']['output'];
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type DeleteRecurrenceInputV2 = {
  deleteFuture: Scalars['Boolean']['input'];
  id: Scalars['Int']['input'];
  memberId?: InputMaybe<Scalars['Int']['input']>;
  startsAt: Scalars['DateTime']['input'];
};

export type DeleteRecurrenceMutationResponse = MutationResponse & {
  __typename?: 'DeleteRecurrenceMutationResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  routine?: Maybe<RoutineResponse>;
  success: Scalars['Boolean']['output'];
};

export type DeleteRewardsCodesInput = {
  ids: Array<Scalars['Int']['input']>;
};

export type DeleteRoutineMutationResponse = MutationResponse & {
  __typename?: 'DeleteRoutineMutationResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  routine?: Maybe<RoutineResponse>;
  success: Scalars['Boolean']['output'];
};

export type DeleteRoutineV2Input = {
  id: Scalars['Int']['input'];
  memberId?: InputMaybe<Scalars['Int']['input']>;
};

export type DeletedMessage = {
  __typename?: 'DeletedMessage';
  deletedAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  messageSentAt: Scalars['DateTime']['output'];
  messageText: Scalars['String']['output'];
  receivedBy: Member;
  sentBy: DashboardUser;
};

export type DestroyFeatureInput = {
  key: Scalars['String']['input'];
};

export type DestroyFeatureResponse = {
  __typename?: 'DestroyFeatureResponse';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export enum DistributionMethod {
  CustomerService = 'CustomerService',
  Email = 'Email',
  Letter = 'Letter',
  Multiuse = 'Multiuse',
  Other = 'Other',
  PrintedTicket = 'PrintedTicket',
  SlickText = 'SlickText',
  Upcall = 'Upcall'
}

export type EarnedReward = {
  __typename?: 'EarnedReward';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
};

export type EditRewardsPlanInput = {
  barc10Amount: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  segments: Array<RewardsPlanSegmentInput>;
  surveyAmount: Scalars['Int']['input'];
};

export type EmailAttendanceReportInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  routineIds: Array<Scalars['Int']['input']>;
};

export type EmailAttendanceReportResponse = MutationResponse & {
  __typename?: 'EmailAttendanceReportResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type Engagement = {
  __typename?: 'Engagement';
  actions?: Maybe<Scalars['String']['output']>;
  duration: Scalars['Int']['output'];
  goalProgress?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  member: Member;
  notes?: Maybe<Scalars['String']['output']>;
  peer: DashboardUser;
  planChanges?: Maybe<Scalars['String']['output']>;
  referrals?: Maybe<Scalars['String']['output']>;
  startedAt: Scalars['DateTime']['output'];
  successful: Scalars['Boolean']['output'];
  type: EngagementType;
};

export type EngagementInput = {
  actions?: InputMaybe<Scalars['String']['input']>;
  duration: Scalars['Int']['input'];
  goalProgress?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['Int']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  planChanges?: InputMaybe<Scalars['String']['input']>;
  referrals?: InputMaybe<Scalars['String']['input']>;
  startedAt: Scalars['DateTime']['input'];
  successful: Scalars['Boolean']['input'];
  type: EngagementType;
};

export type EngagementMutationResponse = MutationResponse & {
  __typename?: 'EngagementMutationResponse';
  code: Scalars['Int']['output'];
  engagement?: Maybe<EngagementResponse>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type EngagementResponse = Engagement | ValidationErrors;

export enum EngagementType {
  InAppMessaging = 'InAppMessaging',
  InPerson = 'InPerson',
  Other = 'Other',
  PhoneCall = 'PhoneCall',
  TextMessaging = 'TextMessaging',
  VideoCall = 'VideoCall'
}

export enum ExperiencingConcernsResponse {
  No = 'No',
  NotCurrently = 'NotCurrently',
  PreferNotToAnswer = 'PreferNotToAnswer',
  Yes = 'Yes'
}

export type Feature = {
  __typename?: 'Feature';
  comment?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  minVersion?: Maybe<Scalars['String']['output']>;
  retiredVersion?: Maybe<Scalars['String']['output']>;
  rolloutPhase: RolloutPhase;
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type FeatureOverride = {
  __typename?: 'FeatureOverride';
  key: Scalars['String']['output'];
  memberIds: Array<Scalars['Int']['output']>;
};

export type FeatureRetireInput = {
  key: Scalars['String']['input'];
};

export type FeatureRolloutInput = {
  key: Scalars['String']['input'];
  rolloutPhase: RolloutPhase;
};

export type FixedItem = Item & {
  __typename?: 'FixedItem';
  faceValue: Scalars['Int']['output'];
  rewardName: Scalars['String']['output'];
  type: ValueType;
  utid: Scalars['String']['output'];
};

export type Gender = {
  __typename?: 'Gender';
  explanation?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type GetClaimCodeInput = {
  rewardId: Scalars['Int']['input'];
};

export type GetClaimCodeResponse = {
  __typename?: 'GetClaimCodeResponse';
  claimCode?: Maybe<Scalars['String']['output']>;
};

export type GratitudeList = {
  __typename?: 'GratitudeList';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  text: Scalars['String']['output'];
};

export type GratitudeListInput = {
  text: Scalars['String']['input'];
};

export type GratitudeListMutationResponse = MutationResponse & {
  __typename?: 'GratitudeListMutationResponse';
  code: Scalars['Int']['output'];
  gratitudeList?: Maybe<GratitudeListResponse>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type GratitudeListResponse = GratitudeList | ValidationErrors;

export type HealthPlan = {
  __typename?: 'HealthPlan';
  id?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
};

export type HealthPlanInfo = {
  __typename?: 'HealthPlanInfo';
  healthPlan: SupportedHealthPlan;
  id: Scalars['Int']['output'];
  insuranceId: Scalars['String']['output'];
};

export type InspirationalQuote = {
  __typename?: 'InspirationalQuote';
  author: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  quote: Scalars['String']['output'];
  saved: Scalars['Boolean']['output'];
};

export type InspirationalQuoteMutationResponse = MutationResponse & {
  __typename?: 'InspirationalQuoteMutationResponse';
  code: Scalars['Int']['output'];
  inspirationalQuote?: Maybe<InspirationalQuote>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type InsuranceProfileInput = {
  insuranceProviderId?: InputMaybe<Scalars['Int']['input']>;
  memberId: Scalars['String']['input'];
};

export type Intake = {
  __typename?: 'Intake';
  complementaryRecoveryPlan?: Maybe<Scalars['String']['output']>;
  contactNotes?: Maybe<Scalars['String']['output']>;
  engagementFrequency?: Maybe<Scalars['Int']['output']>;
  engagementFrequencyInterval?: Maybe<IntakeEngagementFrequencyInterval>;
  hopesAndDreams?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  strengthsAndInterests?: Maybe<Scalars['String']['output']>;
  supportivePeople: Array<SupportivePerson>;
  textOkay?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  voicemailOkay?: Maybe<Scalars['Boolean']['output']>;
};

export enum IntakeEngagementFrequencyInterval {
  Day = 'Day',
  Month = 'Month',
  Week = 'Week'
}

export type IntakeInput = {
  complementaryRecoveryPlan?: InputMaybe<Scalars['String']['input']>;
  contactNotes?: InputMaybe<Scalars['String']['input']>;
  engagementFrequency?: InputMaybe<Scalars['Int']['input']>;
  engagementFrequencyInterval?: InputMaybe<IntakeEngagementFrequencyInterval>;
  hopesAndDreams?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  strengthsAndInterests?: InputMaybe<Scalars['String']['input']>;
  supportivePeople?: InputMaybe<Array<SupportivePersonInput>>;
  textOkay?: InputMaybe<Scalars['Boolean']['input']>;
  voicemailOkay?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Item = {
  rewardName: Scalars['String']['output'];
  type: ValueType;
  utid: Scalars['String']['output'];
};

export type JoinMeetingInput = {
  date?: InputMaybe<Scalars['Date']['input']>;
  publicMeetingId: Scalars['Int']['input'];
};

export type JoinMeetingResponse = MutationResponse & {
  __typename?: 'JoinMeetingResponse';
  code: Scalars['Int']['output'];
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type Location = {
  __typename?: 'Location';
  formattedAddress: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  name?: Maybe<Scalars['String']['output']>;
  timeZone: Scalars['String']['output'];
};

export type MarkDashboardNotificationsAsSeenMutationResponse = MutationResponse & {
  __typename?: 'MarkDashboardNotificationsAsSeenMutationResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  notifications?: Maybe<Array<DashboardNotification>>;
  success: Scalars['Boolean']['output'];
};

export type MarkEarnedRewardsAsSeenResponse = {
  __typename?: 'MarkEarnedRewardsAsSeenResponse';
  memberV2: Member;
};

export type Member = {
  __typename?: 'Member';
  accountDeactivationDate?: Maybe<Scalars['DateTime']['output']>;
  accountEnabled: Scalars['Boolean']['output'];
  accountStatus: AccountStatus;
  achievementForCategory?: Maybe<Achievement>;
  achievements: Array<Achievement>;
  /** @deprecated no longer used */
  activeRoutines: Array<Routine>;
  activeStreaks: ActiveStreaks;
  attendedOnlineMeetings: Array<AttendedOnlineMeeting>;
  barc10?: Maybe<Barc10>;
  /** @deprecated complete profile removed */
  behavioralHealthProfile?: Maybe<BehavioralHealthProfile>;
  benefits?: Maybe<MemberBenefits>;
  birthDate?: Maybe<Scalars['Date']['output']>;
  challenges: Array<Challenge>;
  checkins: Array<Checkin>;
  createdAt: Scalars['DateTime']['output'];
  csNotes?: Maybe<Scalars['String']['output']>;
  currentChallengeV2?: Maybe<Challenge>;
  eligibilityLastChecked?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  engaged: Scalars['Boolean']['output'];
  engagements: Array<Engagement>;
  /** @deprecated complete profile removed */
  experiencingSudConcerns?: Maybe<ExperiencingConcernsResponse>;
  firstName?: Maybe<Scalars['String']['output']>;
  genders: Array<Scalars['String']['output']>;
  gratitudeLists: Array<GratitudeList>;
  hasActiveBenefits: Scalars['Boolean']['output'];
  hasActiveEligibility?: Maybe<Scalars['Boolean']['output']>;
  hasPrss: Scalars['Boolean']['output'];
  healthPlanInfo?: Maybe<HealthPlanInfo>;
  id: Scalars['Int']['output'];
  intake?: Maybe<Intake>;
  isFreeMember: Scalars['Boolean']['output'];
  isNewMember: Scalars['Boolean']['output'];
  languageTag?: Maybe<Scalars['String']['output']>;
  lastConcludedChallenge?: Maybe<Challenge>;
  lastDaysUsedSubstanceResponse?: Maybe<Scalars['Int']['output']>;
  lastKnownTimeZone: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  mesNotes?: Maybe<Scalars['String']['output']>;
  messaging?: Maybe<Messaging>;
  messagingEnabled: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nextStandardSurvey?: Maybe<NextStandardSurvey>;
  okToContact?: Maybe<OkToContactInput>;
  peerMatchSurvey?: Maybe<PeerMatchSurvey>;
  peerSupportQuestionnaire?: Maybe<PeerSupportQuestionnaire>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  preferredName?: Maybe<Scalars['String']['output']>;
  problematicSubstancesV2?: Maybe<Array<ProblematicSubstance>>;
  progressDate?: Maybe<Scalars['Date']['output']>;
  pronouns: Array<Pronoun>;
  recentLocations: Array<Location>;
  /** @deprecated use progressDate */
  recoveryDate?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated use recurrencesV3 */
  recurrencesV2: Array<RecurrenceV2>;
  recurrencesV3: Array<RecurrenceV3>;
  redeemedRewards: Array<RedeemedReward>;
  releasesOfInformation: Array<ReleaseOfInformation>;
  rewardsBalance: Scalars['Int']['output'];
  rewardsCode?: Maybe<RewardsCode>;
  rewardsEnabled: Scalars['Boolean']['output'];
  rewardsPlan?: Maybe<RewardsPlan>;
  routines: Array<Routine>;
  savedQuotes: Array<InspirationalQuote>;
  /** @deprecated should always show behavioral health */
  shouldShowBehavioralHealth: Scalars['Boolean']['output'];
  /** @deprecated complete profile removed */
  shouldShowSudQuestion: Scalars['Boolean']['output'];
  standardSurveys: Array<StandardSurveyV2>;
  strengths: Array<Strength>;
  subscription?: Maybe<PaidSubscription>;
  timeTravel?: Maybe<Scalars['DateTime']['output']>;
  timeZone: Scalars['String']['output'];
  totalEarned: Scalars['Int']['output'];
  totalEngagementDuration?: Maybe<Scalars['Int']['output']>;
  treatmentProvider?: Maybe<TreatmentProvider>;
  unseenEarnedRewardsCount: Scalars['Int']['output'];
  wellnessGoals: Array<WellnessGoal>;
};


export type MemberAchievementForCategoryArgs = {
  categoryId: Scalars['Int']['input'];
};


export type MemberActiveRoutinesArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MemberAttendedOnlineMeetingsArgs = {
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type MemberChallengesArgs = {
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type MemberCheckinsArgs = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type MemberEngagementsArgs = {
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type MemberGratitudeListsArgs = {
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type MemberRecentLocationsArgs = {
  limit: Scalars['Int']['input'];
};


export type MemberRecurrencesV2Args = {
  fromDate?: InputMaybe<Scalars['DateTime']['input']>;
  timeZone: Scalars['String']['input'];
};


export type MemberRecurrencesV3Args = {
  fromDate?: InputMaybe<Scalars['DateTime']['input']>;
  timeZone: Scalars['String']['input'];
};


export type MemberRoutinesArgs = {
  input?: InputMaybe<RoutinesInput>;
};


export type MemberSavedQuotesArgs = {
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type MemberBenefits = {
  __typename?: 'MemberBenefits';
  activatedAt: Scalars['DateTime']['output'];
  channelLastReadAt?: Maybe<Scalars['DateTime']['output']>;
  contract?: Maybe<Contract>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  lastMessageSentAt?: Maybe<Scalars['DateTime']['output']>;
  maxPotentialRewards: Scalars['Int']['output'];
  messagingChannelUrl?: Maybe<Scalars['String']['output']>;
  messagingEndsAt?: Maybe<Scalars['DateTime']['output']>;
  peer?: Maybe<DashboardUser>;
  rewardsEndsAt?: Maybe<Scalars['DateTime']['output']>;
  sentMessageCount?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type MemberBenefitsFilters = {
  contractId?: InputMaybe<Scalars['Int']['input']>;
  peerId?: InputMaybe<Scalars['Int']['input']>;
};

export type MemberBenefitsMutationResponse = MutationResponse & {
  __typename?: 'MemberBenefitsMutationResponse';
  code: Scalars['Int']['output'];
  memberBenefits?: Maybe<MemberBenefitsResponse>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type MemberBenefitsResponse = MemberBenefits | ValidationErrors;

export type MemberMutationResponse = MutationResponse & {
  __typename?: 'MemberMutationResponse';
  code: Scalars['Int']['output'];
  member?: Maybe<MemberResponse>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type MemberPronounInput = {
  customResponse?: InputMaybe<Scalars['String']['input']>;
  pronounsId: Scalars['Int']['input'];
};

export type MemberResponse = Member | ValidationErrors;

export type MemberSubstanceInput = {
  freeFormResponse?: InputMaybe<Scalars['String']['input']>;
  problematicSubstanceId: Scalars['Int']['input'];
};

export type Messaging = {
  __typename?: 'Messaging';
  messagingId?: Maybe<Scalars['String']['output']>;
};

export enum MessagingBenefitStatus {
  Active = 'Active',
  Expired = 'Expired'
}

export type Mutation = {
  __typename?: 'Mutation';
  addDashboardUserToChannel: AddDashboardUserToChannelResponse;
  answerBarc10V3: Barc10MutationResponse;
  answerPeerSupportQuestionnaire: MemberMutationResponse;
  /** @deprecated complete profile removed */
  answerSUDQuestion: SudQuestionResponse;
  answerStandardSurvey: Scalars['Boolean']['output'];
  assignPeer: MemberBenefitsMutationResponse;
  beginCheckout: BeginCheckoutResponse;
  cancelCheckout: CancelCheckoutResponse;
  cancelSubscription: SubscriptionMutationResponse;
  changeDashboardUserPassword: UpdateDashboardUserResponse;
  createAccountV5: CreateAccountMutationResponse;
  createBulkRoutineV2?: Maybe<CreateBulkRoutineV2Response>;
  createCheckinV3: CheckMutationResponse;
  createContractV2: ContractMutationResponse;
  createDashboardUser: CreateDashboardUserResponse;
  createEngagement: EngagementMutationResponse;
  createFeature?: Maybe<CreateFeatureResponse>;
  createGratitudeList: GratitudeListMutationResponse;
  createRedeemedRewardV2: Member;
  createReleaseOfInformation: ReleaseOfInformationMutationResponse;
  createRewardsCode: RewardsCodeMutationResponse;
  createRewardsCodes: CreateRewardsCodesResponse;
  createRewardsPlan: RewardsPlanResponse;
  createRoutineV2: Routine;
  createSubscription: SubscriptionMutationResponse;
  createTreatmentProvider: TreatmentProviderMutationResponse;
  createWellnessGoal: WellnessGoalMutationResponse;
  deleteAccount: DeleteAccountResponse;
  deleteCheckin: DeleteCheckinResponse;
  deleteDevice?: Maybe<DeleteDeviceResponse>;
  deleteEngagement: EngagementMutationResponse;
  deleteGratitudeList: GratitudeListMutationResponse;
  deleteRecurrence: DeleteRecurrenceMutationResponse;
  deleteReleaseOfInformation: ReleaseOfInformationMutationResponse;
  deleteRewardsCodes: RewardsCodesMutationResponse;
  deleteRoutineV2: DeleteRoutineMutationResponse;
  deleteTreatmentProvider: TreatmentProviderMutationResponse;
  deleteWellnessGoal: WellnessGoalMutationResponse;
  destroyFeature?: Maybe<DestroyFeatureResponse>;
  dismissBARC10CardV2?: Maybe<Barc10>;
  dismissBarc10?: Maybe<Barc10>;
  dismissStandardSurvey?: Maybe<NextStandardSurvey>;
  dismissStandardSurveyCard?: Maybe<NextStandardSurvey>;
  editRewardsPlan: RewardsPlanResponse;
  emailAttendanceReport: EmailAttendanceReportResponse;
  joinMeeting: JoinMeetingResponse;
  markDashboardNotificationsAsSeen: MarkDashboardNotificationsAsSeenMutationResponse;
  markEarnedRewardsAsSeen: MarkEarnedRewardsAsSeenResponse;
  registerDeviceToken?: Maybe<RegisterDeviceTokenResponse>;
  registerLastActive: RegisterLastActiveResponse;
  removeDashboardUserFromChannel: RemoveDashboardUserFromChannelResponse;
  renewMessagingSessionToken: RenewMessagingSessionTokenResponse;
  /** @deprecated Email and password sign in deprecated */
  requestChangeEmailVerification: RequestVerificationResponse;
  requestChangePhoneVerification: RequestVerificationResponse;
  requestDashboardPasswordReset: RequestDashboardPasswordResetResponse;
  requestOnboardingPhoneVerification: RequestVerificationResponse;
  /** @deprecated Email and password sign in deprecated */
  requestPasswordResetVerification: RequestVerificationResponse;
  requestSigninVerificationCode: RequestVerificationResponse;
  resetPasswordForDashboardUser: ResetPasswordMutationResponse;
  retireFeature: Feature;
  rewardCredentials: RewardCredentialsResponse;
  rolloutFeature: Feature;
  saveQuote: InspirationalQuoteMutationResponse;
  sendMessageToMembers: SendMessageToMembersResponse;
  setAccountStatusV2: SetAccountStatusMutationResponse;
  setRewardsEnabled: SetRewardsEnabledResponse;
  setRoutineStatus: SetRoutineStatusResponse;
  /** @deprecated Email and password sign in deprecated */
  signIn: SignInResponse;
  signInDashboardUserV2: SignInDashboardUserResponseV2;
  signInV2: SignInResponseV2;
  /** @deprecated Use signInV2 */
  signInWithPhoneNumber: SignInWithPhoneNumberResponse;
  signOut: SignOutResponse;
  submitPeerMatchSurvey: PeerMatchSurvey;
  submitSmartRoutineFeedback: SmartRoutineFeedbackResponse;
  unSaveQuote: InspirationalQuoteMutationResponse;
  unassignPeer: MemberBenefitsMutationResponse;
  updateAwayMessage: UpdateDashboardUserResponse;
  /** @deprecated complete profile removed */
  updateBehavioralHealthProfileV2: MemberMutationResponse;
  updateBrandKeys: ContractMutationResponse;
  updateContract: ContractMutationResponse;
  updateDashboardUser: UpdateDashboardUserResponse;
  updateEligibilityInfo: MemberMutationResponse;
  updateEngagement: EngagementMutationResponse;
  updateFeatureOverrides?: Maybe<UpdateFeatureOverridesResponse>;
  updateGratitudeList: GratitudeListMutationResponse;
  updateIntake: MemberMutationResponse;
  updateMemberTreatmentProvider?: Maybe<Member>;
  updateMemberV3: Member;
  updateMembersBenefits: Array<Member>;
  updateNotes: MemberMutationResponse;
  updateOkToContact: MemberMutationResponse;
  /** @deprecated use updateDashboardUser */
  updatePeerProfile: UpdateDashboardUserResponse;
  updatePhoneNumber: UpdatePhoneNumberResponse;
  /** @deprecated complete profile removed */
  updateProblematicSubstancesV2: MemberMutationResponse;
  updateReleaseOfInformation: ReleaseOfInformationMutationResponse;
  updateRewardsCode: RewardsCodeMutationResponse;
  updateRewardsCodes: RewardsCodesMutationResponse;
  updateRoutineV3: UpdateRoutineMutationResponse;
  updateStrengths: MemberMutationResponse;
  updateSubscriptionPaymentMethod: SubscriptionMutationResponse;
  updateSubscriptionPrice: SubscriptionMutationResponse;
  updateTimeTravelV3: UpdateTimeTravelResponse;
  updateTreatmentProvider: TreatmentProviderMutationResponse;
  updateWellnessGoal: WellnessGoalMutationResponse;
  upgradeAccountV3: UpgradeAccountMutationResponse;
  upgradeByAdmin: MemberMutationResponse;
  upsertFeatureOverride: Override;
  validateOnboardingCodeV2: ValidateOnboardingCodeMutationResponse;
  validatePromoCode: PromoCodeMutationResponse;
  verifyChangePhoneV2: VerifyChangeCredentialMutationResponse;
};


export type MutationAddDashboardUserToChannelArgs = {
  url: Scalars['String']['input'];
};


export type MutationAnswerBarc10V3Args = {
  input: Barc10V3Input;
};


export type MutationAnswerPeerSupportQuestionnaireArgs = {
  input: PeerSupportQuestionnaireInput;
};


export type MutationAnswerSudQuestionArgs = {
  input: SudQuestionInput;
};


export type MutationAnswerStandardSurveyArgs = {
  input: StandardSurveyInput;
};


export type MutationAssignPeerArgs = {
  input: AssignPeerInput;
};


export type MutationBeginCheckoutArgs = {
  input: BeginCheckoutInput;
};


export type MutationCancelCheckoutArgs = {
  setupIntentId: Scalars['String']['input'];
};


export type MutationChangeDashboardUserPasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationCreateAccountV5Args = {
  input?: InputMaybe<CreateAccountV5Input>;
};


export type MutationCreateBulkRoutineV2Args = {
  input: CreateBulkRoutineV2Input;
};


export type MutationCreateCheckinV3Args = {
  input: CreateCheckinInput;
};


export type MutationCreateContractV2Args = {
  input: ContractInput;
};


export type MutationCreateDashboardUserArgs = {
  input: CreateDashboardUserInput;
};


export type MutationCreateEngagementArgs = {
  input: EngagementInput;
};


export type MutationCreateFeatureArgs = {
  input: CreateFeatureInput;
};


export type MutationCreateGratitudeListArgs = {
  input: GratitudeListInput;
};


export type MutationCreateRedeemedRewardV2Args = {
  input: CreateRedeemedRewardInput;
};


export type MutationCreateReleaseOfInformationArgs = {
  input: ReleaseOfInformationInput;
  memberId: Scalars['Int']['input'];
};


export type MutationCreateRewardsCodeArgs = {
  input: CreateRewardsCodeInput;
};


export type MutationCreateRewardsCodesArgs = {
  input: CreateRewardsCodesInput;
};


export type MutationCreateRewardsPlanArgs = {
  input: CreateRewardsPlanInput;
};


export type MutationCreateRoutineV2Args = {
  input: CreateRoutineV2Input;
};


export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};


export type MutationCreateTreatmentProviderArgs = {
  input: CreateTreatmentProviderInput;
};


export type MutationCreateWellnessGoalArgs = {
  input: WellnessGoalInput;
  memberId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDeleteAccountArgs = {
  input: DeleteAccountInput;
};


export type MutationDeleteCheckinArgs = {
  input: DeleteCheckinInput;
};


export type MutationDeleteDeviceArgs = {
  input: DeleteDeviceInput;
};


export type MutationDeleteEngagementArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteGratitudeListArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteRecurrenceArgs = {
  input: DeleteRecurrenceInputV2;
};


export type MutationDeleteReleaseOfInformationArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteRewardsCodesArgs = {
  input: DeleteRewardsCodesInput;
};


export type MutationDeleteRoutineV2Args = {
  input: DeleteRoutineV2Input;
};


export type MutationDeleteTreatmentProviderArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteWellnessGoalArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDestroyFeatureArgs = {
  input: DestroyFeatureInput;
};


export type MutationEditRewardsPlanArgs = {
  input: EditRewardsPlanInput;
};


export type MutationEmailAttendanceReportArgs = {
  input: EmailAttendanceReportInput;
};


export type MutationJoinMeetingArgs = {
  input: JoinMeetingInput;
};


export type MutationMarkDashboardNotificationsAsSeenArgs = {
  ids: Array<Scalars['Int']['input']>;
};


export type MutationRegisterDeviceTokenArgs = {
  input: RegisterDeviceTokenInput;
};


export type MutationRegisterLastActiveArgs = {
  input?: InputMaybe<RegisterLastActiveInput>;
};


export type MutationRemoveDashboardUserFromChannelArgs = {
  url: Scalars['String']['input'];
};


export type MutationRequestChangeEmailVerificationArgs = {
  input: RequestEmailVerificationInput;
};


export type MutationRequestChangePhoneVerificationArgs = {
  input: RequestPhoneVerificationInput;
};


export type MutationRequestDashboardPasswordResetArgs = {
  email: Scalars['String']['input'];
};


export type MutationRequestOnboardingPhoneVerificationArgs = {
  input: RequestPhoneVerificationInput;
};


export type MutationRequestPasswordResetVerificationArgs = {
  input: RequestEmailVerificationInput;
};


export type MutationRequestSigninVerificationCodeArgs = {
  input: RequestSmsVerificationCodeInput;
};


export type MutationResetPasswordForDashboardUserArgs = {
  input: ResetPasswordInput;
};


export type MutationRetireFeatureArgs = {
  input: FeatureRetireInput;
};


export type MutationRewardCredentialsArgs = {
  input: RewardCredentialsInput;
};


export type MutationRolloutFeatureArgs = {
  input: FeatureRolloutInput;
};


export type MutationSaveQuoteArgs = {
  quoteId: Scalars['Int']['input'];
};


export type MutationSendMessageToMembersArgs = {
  input: SendMessageToMembersInput;
};


export type MutationSetAccountStatusV2Args = {
  input: SetAccountStatusInput;
};


export type MutationSetRewardsEnabledArgs = {
  input: SetRewardsEnabledInput;
};


export type MutationSetRoutineStatusArgs = {
  input: SetRoutineStatusInput;
};


export type MutationSignInArgs = {
  input: SignInInput;
};


export type MutationSignInDashboardUserV2Args = {
  input: SignInDashboardUserInput;
};


export type MutationSignInV2Args = {
  input: SignInWithPhoneNumberInput;
};


export type MutationSignInWithPhoneNumberArgs = {
  input: SignInWithPhoneNumberInput;
};


export type MutationSignOutArgs = {
  input: SignOutInput;
};


export type MutationSubmitPeerMatchSurveyArgs = {
  input: PeerMatchSurveyInput;
};


export type MutationSubmitSmartRoutineFeedbackArgs = {
  input: SmartRoutineFeedbackInput;
};


export type MutationUnSaveQuoteArgs = {
  quoteId: Scalars['Int']['input'];
};


export type MutationUnassignPeerArgs = {
  memberId: Scalars['Int']['input'];
};


export type MutationUpdateAwayMessageArgs = {
  input: UpdateAwayMessageInput;
};


export type MutationUpdateBehavioralHealthProfileV2Args = {
  input: UpdateBehavioralHealthProfileV2Input;
};


export type MutationUpdateBrandKeysArgs = {
  input: UpdateBrandKeysInput;
};


export type MutationUpdateContractArgs = {
  id: Scalars['Int']['input'];
  input: ContractInput;
};


export type MutationUpdateDashboardUserArgs = {
  input: UpdateDashboardUserInput;
};


export type MutationUpdateEligibilityInfoArgs = {
  input: UpdateEligibilityInput;
};


export type MutationUpdateEngagementArgs = {
  id: Scalars['Int']['input'];
  input: EngagementInput;
};


export type MutationUpdateFeatureOverridesArgs = {
  input: UpdateFeatureOverridesInput;
};


export type MutationUpdateGratitudeListArgs = {
  id: Scalars['Int']['input'];
  input: GratitudeListInput;
};


export type MutationUpdateIntakeArgs = {
  input: IntakeInput;
  memberId: Scalars['Int']['input'];
};


export type MutationUpdateMemberTreatmentProviderArgs = {
  input: UpdateMemberTreatmentProviderInput;
};


export type MutationUpdateMemberV3Args = {
  input: UpdateMemberV3Input;
};


export type MutationUpdateMembersBenefitsArgs = {
  input: UpdateMembersBenefitsInput;
};


export type MutationUpdateNotesArgs = {
  input: UpdateNotesInput;
};


export type MutationUpdateOkToContactArgs = {
  input: OkToContactInput;
};


export type MutationUpdatePeerProfileArgs = {
  input: UpdatePeerProfileInput;
};


export type MutationUpdatePhoneNumberArgs = {
  input: UpdatePhoneNumberInput;
};


export type MutationUpdateProblematicSubstancesV2Args = {
  input: UpdateProblematicSubstancesV2Input;
};


export type MutationUpdateReleaseOfInformationArgs = {
  id: Scalars['Int']['input'];
  input: ReleaseOfInformationInput;
};


export type MutationUpdateRewardsCodeArgs = {
  input: UpdateRewardsCodeInput;
};


export type MutationUpdateRewardsCodesArgs = {
  input: UpdateRewardsCodesInput;
};


export type MutationUpdateRoutineV3Args = {
  input: UpdateRoutineV3Input;
};


export type MutationUpdateStrengthsArgs = {
  input: StrengthInput;
  memberId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUpdateSubscriptionPaymentMethodArgs = {
  setupIntentId: Scalars['String']['input'];
};


export type MutationUpdateSubscriptionPriceArgs = {
  input: UpdateSubscriptionPriceInput;
};


export type MutationUpdateTimeTravelV3Args = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationUpdateTreatmentProviderArgs = {
  input: UpdateTreatmentProviderInput;
};


export type MutationUpdateWellnessGoalArgs = {
  id: Scalars['Int']['input'];
  input: WellnessGoalInput;
};


export type MutationUpgradeAccountV3Args = {
  input: UpgradeAccountInput;
};


export type MutationUpgradeByAdminArgs = {
  input: UpgradeByAdminInput;
};


export type MutationUpsertFeatureOverrideArgs = {
  input: UpsertFeatureOverrideInput;
};


export type MutationValidateOnboardingCodeV2Args = {
  input: ValidateOnboardingCodeInput;
};


export type MutationValidatePromoCodeArgs = {
  input: ValidatePromoCodeInput;
};


export type MutationVerifyChangePhoneV2Args = {
  input: VerifyChangeCredentialInput;
};

export type MutationResponse = {
  code: Scalars['Int']['output'];
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type NextStandardSurvey = {
  __typename?: 'NextStandardSurvey';
  expiresAt: Scalars['DateTime']['output'];
  rewardAmount: Scalars['Int']['output'];
  shouldShow: Scalars['Boolean']['output'];
  shouldShowCard: Scalars['Boolean']['output'];
};

export enum OkToContactInput {
  No = 'No',
  TextMessageOnly = 'TextMessageOnly',
  Yes = 'Yes'
}

export enum Order {
  Asc = 'asc',
  Desc = 'desc'
}

export type Override = {
  __typename?: 'Override';
  key: Scalars['String']['output'];
  memberId: Scalars['Int']['output'];
  value: Scalars['Boolean']['output'];
};

export type PaidSubscription = {
  __typename?: 'PaidSubscription';
  cancelAtPeriodEnd: Scalars['Boolean']['output'];
  currentPeriodEnd: Scalars['DateTime']['output'];
  currentPeriodStart: Scalars['DateTime']['output'];
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  gracePeriodEnd: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  price?: Maybe<Price>;
  startDate: Scalars['DateTime']['output'];
  status: SubscriptionStatus;
};

export type PeerMatchSurvey = {
  __typename?: 'PeerMatchSurvey';
  extraInformation: Scalars['String']['output'];
  genderIdentity: Array<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  peerWorkAreas: Array<Scalars['String']['output']>;
  preferredCommunication: Array<Maybe<Scalars['String']['output']>>;
  pronouns: Scalars['String']['output'];
  recoveryPlan: Array<Scalars['String']['output']>;
  socialSupport: Scalars['String']['output'];
};

export type PeerMatchSurveyInput = {
  extraInformation: Scalars['String']['input'];
  genderIdentity: Array<Scalars['String']['input']>;
  peerWorkAreas: Array<Scalars['String']['input']>;
  preferredCommunication: Array<Scalars['String']['input']>;
  pronounsIds: Array<Scalars['Int']['input']>;
  recoveryPlan: Array<Scalars['String']['input']>;
  socialSupport: Scalars['String']['input'];
};

export type PeerSupportArea = {
  __typename?: 'PeerSupportArea';
  group: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type PeerSupportQuestionnaire = {
  __typename?: 'PeerSupportQuestionnaire';
  considerations?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  otherConcerns?: Maybe<Scalars['String']['output']>;
  peerSupportAreas?: Maybe<Array<PeerSupportArea>>;
  socialSupportSystem?: Maybe<SocialSupportSystemResponse>;
};

export type PeerSupportQuestionnaireInput = {
  considerations?: InputMaybe<Scalars['String']['input']>;
  otherConcerns?: InputMaybe<Scalars['String']['input']>;
  peerSupportAreaIds: Array<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Array<MemberPronounInput>>;
  socialSupportSystem?: InputMaybe<SocialSupportSystemResponse>;
};

export type Price = {
  __typename?: 'Price';
  amount: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  productKey: Scalars['String']['output'];
  recurringInterval: Scalars['String']['output'];
  recurringIntervalCount?: Maybe<Scalars['Int']['output']>;
};

export type ProblematicSubstance = {
  __typename?: 'ProblematicSubstance';
  freeFormResponse?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type PromoCode = {
  __typename?: 'PromoCode';
  amountOff?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  percentOff?: Maybe<Scalars['Float']['output']>;
};

export type PromoCodeMutationResponse = MutationResponse & {
  __typename?: 'PromoCodeMutationResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  promoCode?: Maybe<PromoCode>;
  success: Scalars['Boolean']['output'];
};

export type Pronoun = {
  __typename?: 'Pronoun';
  customResponse?: Maybe<Scalars['String']['output']>;
  /** @deprecated example removed */
  example: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type PublicMeeting = {
  __typename?: 'PublicMeeting';
  category: Category;
  duration: Scalars['Int']['output'];
  header: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  nextStartsAt?: Maybe<Scalars['DateTime']['output']>;
  recurrence: Array<Scalars['String']['output']>;
  routine?: Maybe<Routine>;
  timeZone: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum PublicMeetingType {
  General = 'General',
  Specialized = 'Specialized'
}

export type PublicOnlineMeeting = {
  __typename?: 'PublicOnlineMeeting';
  category: Category;
  duration: Scalars['Int']['output'];
  endTime: Scalars['DateTime']['output'];
  header: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  routine?: Maybe<Routine>;
  startTime: Scalars['DateTime']['output'];
  timeZone: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type PublicOnlineMeetingV3 = {
  __typename?: 'PublicOnlineMeetingV3';
  category: Category;
  duration: Scalars['Int']['output'];
  header: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** @deprecated use url */
  link?: Maybe<Scalars['String']['output']>;
  routine?: Maybe<Routine>;
  startTime: Scalars['DateTime']['output'];
  timeZone: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};


export type PublicOnlineMeetingV3LinkArgs = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PublicOnlineMeetings = {
  __typename?: 'PublicOnlineMeetings';
  generalMeetings: Array<PublicOnlineMeeting>;
  specializedMeetings: Array<PublicOnlineMeeting>;
};

export type PublicOnlineMeetingsV3 = {
  __typename?: 'PublicOnlineMeetingsV3';
  generalMeetings: Array<PublicOnlineMeetingV3>;
  specializedMeetings: Array<PublicOnlineMeetingV3>;
};

export type Query = {
  __typename?: 'Query';
  appVersion: AppVersion;
  barc10Questions: Array<Barc10Question>;
  /** @deprecated complete profile removed */
  behavioralHealthConcerns: Array<BehavioralHealthConcern>;
  challengeStructures: Array<ChallengeStructure>;
  contract?: Maybe<Contract>;
  contracts: Array<Contract>;
  currentAppVersion: Scalars['String']['output'];
  dashboardNotifications: QueryDashboardNotificationsResponse;
  dashboardUser: DashboardUser;
  dashboardUsers: Array<DashboardUser>;
  deletedMessages: Array<DeletedMessage>;
  featureOverrides?: Maybe<Array<FeatureOverride>>;
  features: Array<Feature>;
  findMember?: Maybe<Member>;
  genders: Array<Gender>;
  getBarc10Surveys: Array<Barc10Survey>;
  giftCardBrands: Array<Brand>;
  groups: Array<RoutineCategoryGroup>;
  healthPlans: Array<HealthPlan>;
  lossOfEligibilityReport: Array<Member>;
  memberRewardsStatusAudits: Array<Audit>;
  memberV2: Member;
  multiuseRewardsCodes: RewardsCodesResponse;
  peer?: Maybe<DashboardUser>;
  peerStats: Array<DashboardUser>;
  peerSupportAreas: Array<PeerSupportArea>;
  peers: Array<DashboardUser>;
  prices: Array<Price>;
  /** @deprecated complete profile removed */
  problematicSubstances: Array<ProblematicSubstance>;
  pronouns: Array<Pronoun>;
  publicMeeting?: Maybe<PublicMeeting>;
  publicMeetings: Array<PublicMeeting>;
  /** @deprecated use publicMeeting */
  publicOnlineMeeting?: Maybe<PublicOnlineMeetingV3>;
  /** @deprecated use publicOnlineMeetingsV3 */
  publicOnlineMeetingsV2: PublicOnlineMeetings;
  /** @deprecated use publicMeetings */
  publicOnlineMeetingsV3: PublicOnlineMeetingsV3;
  queryChallenges: QueryChallengesResponse;
  queryEngagementsV2: QueryEngagementsResponse;
  queryMembers: QueryMembersResponse;
  queryRedeemedRewards: QueryRedeemedRewardsResponse;
  queryStandardSurveys: QueryStandardSurveysResponse;
  queryWellnessGoals: Array<WellnessGoal>;
  randomQuote: InspirationalQuote;
  rewardsCatalog: Array<Brand>;
  rewardsCode?: Maybe<RewardsCode>;
  rewardsCodes: RewardsCodesResponse;
  rewardsPlans: Array<RewardsPlan>;
  routine: Routine;
  routineRecurrenceV2?: Maybe<RecurrenceV3>;
  smartRoutine?: Maybe<SmartRoutine>;
  /** @deprecated use member.rewardsProgramStats */
  stats: Stats;
  supportedHealthPlans: Array<SupportedHealthPlan>;
  treatmentProviders: Array<TreatmentProvider>;
  validateActivationCode: ValidateActivationCodeResponse;
  wellnessGoal?: Maybe<WellnessGoal>;
};


export type QueryContractArgs = {
  id: Scalars['Int']['input'];
};


export type QueryDashboardNotificationsArgs = {
  input?: InputMaybe<QueryDashboardNotificationsInput>;
};


export type QueryFeatureOverridesArgs = {
  key: Scalars['String']['input'];
};


export type QueryFindMemberArgs = {
  memberId: Scalars['Int']['input'];
};


export type QueryGetBarc10SurveysArgs = {
  memberId: Scalars['Int']['input'];
};


export type QueryGroupsArgs = {
  type?: InputMaybe<RoutineType>;
};


export type QueryMemberRewardsStatusAuditsArgs = {
  memberId: Scalars['Int']['input'];
};


export type QueryMultiuseRewardsCodesArgs = {
  input: RewardsCodesInput;
};


export type QueryPeerArgs = {
  peerId: Scalars['Int']['input'];
};


export type QueryPublicMeetingArgs = {
  id: Scalars['Int']['input'];
};


export type QueryPublicMeetingsArgs = {
  type: PublicMeetingType;
};


export type QueryPublicOnlineMeetingArgs = {
  id: Scalars['Int']['input'];
};


export type QueryQueryChallengesArgs = {
  input: QueryChallengesInput;
};


export type QueryQueryEngagementsV2Args = {
  input: QueryEngagementsInput;
};


export type QueryQueryMembersArgs = {
  input: QueryMembersInput;
};


export type QueryQueryRedeemedRewardsArgs = {
  input: QueryRedeemedRewardsInput;
};


export type QueryQueryStandardSurveysArgs = {
  input: QueryStandardSurveysInput;
};


export type QueryQueryWellnessGoalsArgs = {
  input: QueryWellnessGoalsInput;
};


export type QueryRewardsCodeArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRewardsCodesArgs = {
  input: RewardsCodesInput;
};


export type QueryRoutineArgs = {
  input: RoutineQueryInput;
};


export type QueryRoutineRecurrenceV2Args = {
  id: Scalars['Int']['input'];
  startsAt: Scalars['DateTime']['input'];
};


export type QuerySmartRoutineArgs = {
  input: SmartRoutineQueryInput;
};


export type QueryValidateActivationCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryWellnessGoalArgs = {
  id: Scalars['Int']['input'];
};

export type QueryByField = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type QueryChallengesInput = {
  memberId?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryChallengesResponse = QueryResponse & {
  __typename?: 'QueryChallengesResponse';
  challenges: Array<Challenge>;
  cursor?: Maybe<Scalars['String']['output']>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type QueryDashboardNotificationsInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryDashboardNotificationsResponse = QueryResponse & {
  __typename?: 'QueryDashboardNotificationsResponse';
  cursor?: Maybe<Scalars['String']['output']>;
  notifications: Array<DashboardNotification>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  totalUnseenCount: Scalars['Int']['output'];
};

export type QueryEngagementsInput = {
  memberId?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  peerId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryEngagementsResponse = QueryResponse & {
  __typename?: 'QueryEngagementsResponse';
  cursor?: Maybe<Scalars['String']['output']>;
  engagements: Array<Engagement>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type QueryMembersInput = {
  benefits?: InputMaybe<MemberBenefitsFilters>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  messagingBenefitStatus?: InputMaybe<MessagingBenefitStatus>;
  order?: InputMaybe<Order>;
  orderBy?: InputMaybe<QueryMembersOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  queryByFields?: InputMaybe<QueryByField>;
  treatmentProviderId?: InputMaybe<Scalars['Int']['input']>;
};

export enum QueryMembersOrderBy {
  AccountDeactivationDate = 'AccountDeactivationDate',
  BenefitsActivatedAt = 'BenefitsActivatedAt',
  ContractName = 'ContractName',
  CreatedAt = 'CreatedAt',
  EarnedRewards = 'EarnedRewards',
  Id = 'ID',
  LastMessageRead = 'LastMessageRead',
  LastMessageSent = 'LastMessageSent',
  MaxPotentialRewards = 'MaxPotentialRewards',
  MessageCount = 'MessageCount',
  MessagingEndsAt = 'MessagingEndsAt',
  Name = 'Name',
  ProgressDate = 'ProgressDate',
  RewardsEndsAt = 'RewardsEndsAt',
  TreatmentProviderName = 'TreatmentProviderName'
}

export type QueryMembersResponse = QueryResponse & {
  __typename?: 'QueryMembersResponse';
  cursor?: Maybe<Scalars['String']['output']>;
  membersV2: Array<Member>;
  order: Order;
  orderBy: QueryMembersOrderBy;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type QueryParams = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Order>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type QueryRedeemedRewardsInput = {
  memberId?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryRedeemedRewardsResponse = QueryResponse & {
  __typename?: 'QueryRedeemedRewardsResponse';
  cursor?: Maybe<Scalars['String']['output']>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  rewards: Array<RedeemedReward>;
  totalCount: Scalars['Int']['output'];
};

export type QueryResponse = {
  cursor?: Maybe<Scalars['String']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type QueryStandardSurveysInput = {
  memberId?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryStandardSurveysResponse = QueryResponse & {
  __typename?: 'QueryStandardSurveysResponse';
  cursor?: Maybe<Scalars['String']['output']>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  surveys: Array<StandardSurveyV2>;
  totalCount: Scalars['Int']['output'];
};

export type QueryWellnessGoalsInput = {
  memberId?: InputMaybe<Scalars['Int']['input']>;
};

export enum RoiStatus {
  Sent = 'Sent',
  Signed = 'Signed'
}

export type RecurrenceV2 = {
  __typename?: 'RecurrenceV2';
  anytime: Scalars['Boolean']['output'];
  category?: Maybe<Category>;
  checkin?: Maybe<Checkin>;
  creationInfo?: Maybe<RoutineCreationInfo>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  isFloating: Scalars['Boolean']['output'];
  link?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Location>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  origin: RoutineOrigin;
  originalStartsAt?: Maybe<Scalars['DateTime']['output']>;
  parentId?: Maybe<Scalars['Int']['output']>;
  publicMeeting?: Maybe<PublicOnlineMeetingV3>;
  recurrence: Array<Scalars['String']['output']>;
  reminders?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  startsAt: Scalars['DateTime']['output'];
  status: RoutineStatusType;
  statusChanged?: Maybe<Scalars['DateTime']['output']>;
  type: RoutineType;
  uuid: Scalars['String']['output'];
  wellnessGoals: Array<WellnessGoal>;
};

export type RecurrenceV3 = {
  __typename?: 'RecurrenceV3';
  anytime: Scalars['Boolean']['output'];
  category?: Maybe<Category>;
  checkin?: Maybe<Checkin>;
  creationInfo?: Maybe<RoutineCreationInfo>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  isFloating: Scalars['Boolean']['output'];
  link?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Location>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  origin: RoutineOrigin;
  originalStartsAt?: Maybe<Scalars['DateTime']['output']>;
  parentId?: Maybe<Scalars['Int']['output']>;
  publicMeeting?: Maybe<PublicOnlineMeetingV3>;
  recurrence: Array<Scalars['String']['output']>;
  reminders?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  startsAt: Scalars['DateTime']['output'];
  status: RoutineStatusType;
  statusChanged?: Maybe<Scalars['DateTime']['output']>;
  type: RoutineType;
  uuid: Scalars['String']['output'];
  wellnessGoals: Array<WellnessGoal>;
};


export type RecurrenceV3CheckinArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type RedeemedReward = {
  __typename?: 'RedeemedReward';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isRedeemed?: Maybe<Scalars['Boolean']['output']>;
  purchasedAt?: Maybe<Scalars['DateTime']['output']>;
  referenceOrderId?: Maybe<Scalars['String']['output']>;
  test: Scalars['Boolean']['output'];
  vendor?: Maybe<Scalars['String']['output']>;
};

export type RegisterDeviceTokenInput = {
  deviceId: Scalars['String']['input'];
  platform: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type RegisterDeviceTokenResponse = {
  __typename?: 'RegisterDeviceTokenResponse';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type RegisterLastActiveInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
};

export type RegisterLastActiveResponse = {
  __typename?: 'RegisterLastActiveResponse';
  activeStreaks: ActiveStreaks;
};

export type ReleaseOfInformation = {
  __typename?: 'ReleaseOfInformation';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  relationship?: Maybe<Scalars['String']['output']>;
  status: RoiStatus;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type ReleaseOfInformationInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  relationship?: InputMaybe<Scalars['String']['input']>;
  status: RoiStatus;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type ReleaseOfInformationMutationResponse = MutationResponse & {
  __typename?: 'ReleaseOfInformationMutationResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  releaseOfInformation?: Maybe<ReleaseOfInformationResponse>;
  success: Scalars['Boolean']['output'];
};

export type ReleaseOfInformationResponse = ReleaseOfInformation | ValidationErrors;

export type RemoveDashboardUserFromChannelResponse = MutationResponse & {
  __typename?: 'RemoveDashboardUserFromChannelResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type RenewMessagingSessionTokenResponse = {
  __typename?: 'RenewMessagingSessionTokenResponse';
  sessionToken?: Maybe<SessionToken>;
};

export type RequestDashboardPasswordResetResponse = MutationResponse & {
  __typename?: 'RequestDashboardPasswordResetResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type RequestEmailVerificationInput = {
  email: Scalars['String']['input'];
};

export type RequestPhoneVerificationInput = {
  phoneNumber: Scalars['String']['input'];
};

export type RequestSmsVerificationCodeInput = {
  phoneNumber: Scalars['String']['input'];
};

export type RequestVerificationResponse = {
  __typename?: 'RequestVerificationResponse';
  sendTime: Scalars['DateTime']['output'];
  to: Scalars['String']['output'];
};

export type ResetPasswordInput = {
  password: Scalars['String']['input'];
};

export type ResetPasswordMutationResponse = MutationResponse & {
  __typename?: 'ResetPasswordMutationResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  session?: Maybe<SessionResponse>;
  success: Scalars['Boolean']['output'];
};

export type RewardCredentialsInput = {
  rewardId: Scalars['Int']['input'];
};

export type RewardCredentialsResponse = {
  __typename?: 'RewardCredentialsResponse';
  claimCode?: Maybe<Scalars['String']['output']>;
  pin?: Maybe<Scalars['String']['output']>;
  redemptionInstructions?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type RewardsCode = {
  __typename?: 'RewardsCode';
  code: Scalars['String']['output'];
  contract: Contract;
  createdAt: Scalars['DateTime']['output'];
  distributionMethod?: Maybe<DistributionMethod>;
  id: Scalars['Int']['output'];
  redemptionLimit: Scalars['Int']['output'];
  redemptionsRemaining: Scalars['Int']['output'];
  startingBalance?: Maybe<Scalars['Int']['output']>;
  treatmentProvider?: Maybe<TreatmentProvider>;
};

export type RewardsCodeMutationResponse = MutationResponse & {
  __typename?: 'RewardsCodeMutationResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  rewardsCode?: Maybe<RewardsCodeResponse>;
  success: Scalars['Boolean']['output'];
};

export type RewardsCodeResponse = RewardsCode | ValidationErrors;

export type RewardsCodesInput = {
  contractId?: InputMaybe<Scalars['Int']['input']>;
  distributionMethod?: InputMaybe<DistributionMethod>;
  order?: InputMaybe<Order>;
  orderBy?: InputMaybe<RewardsCodesOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  treatmentProviderId?: InputMaybe<Scalars['Int']['input']>;
};

export type RewardsCodesMutationResponse = MutationResponse & {
  __typename?: 'RewardsCodesMutationResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  rewardsCodes?: Maybe<Array<RewardsCodeResponse>>;
  success: Scalars['Boolean']['output'];
};

export enum RewardsCodesOrderBy {
  Code = 'Code',
  ContractName = 'ContractName',
  CreatedAt = 'CreatedAt',
  DistributionMethod = 'DistributionMethod',
  TreatmentProviderName = 'TreatmentProviderName'
}

export type RewardsCodesResponse = QueryResponse & {
  __typename?: 'RewardsCodesResponse';
  cursor?: Maybe<Scalars['String']['output']>;
  order: Order;
  orderBy: RewardsCodesOrderBy;
  page?: Maybe<Scalars['Int']['output']>;
  pageSize: Scalars['Int']['output'];
  rewardsCodes: Array<RewardsCode>;
  totalCount: Scalars['Int']['output'];
};

export type RewardsPlan = {
  __typename?: 'RewardsPlan';
  activeMembers?: Maybe<Scalars['Int']['output']>;
  barc10Amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  segments: Array<RewardsPlanSegment>;
  surveyAmount: Scalars['Int']['output'];
};

export type RewardsPlanMutationResponse = RewardsPlan | ValidationErrors;

export type RewardsPlanResponse = MutationResponse & {
  __typename?: 'RewardsPlanResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  rewardsPlan?: Maybe<RewardsPlanMutationResponse>;
  success: Scalars['Boolean']['output'];
};

export type RewardsPlanSegment = {
  __typename?: 'RewardsPlanSegment';
  challengeStructure: ChallengeStructure;
  id: Scalars['Int']['output'];
  thresholdAmount: Scalars['Int']['output'];
};

export type RewardsPlanSegmentInput = {
  challengeStructureId: Scalars['Int']['input'];
  thresholdAmount: Scalars['Int']['input'];
};

export enum Role {
  Admin = 'Admin',
  MemberEngagementSpecialist = 'MemberEngagementSpecialist',
  Peer = 'Peer',
  PeerSupervisor = 'PeerSupervisor'
}

export enum RolloutPhase {
  Dev = 'Dev',
  Initial = 'Initial',
  Off = 'Off',
  On = 'On'
}

export type Routine = {
  __typename?: 'Routine';
  anytime: Scalars['Boolean']['output'];
  category?: Maybe<Category>;
  closestStartsAt: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  creationInfo?: Maybe<RoutineCreationInfo>;
  deletable?: Maybe<Scalars['Boolean']['output']>;
  duration: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isChild?: Maybe<Scalars['Boolean']['output']>;
  lastCheckin?: Maybe<Checkin>;
  link?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Location>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  origin: RoutineOrigin;
  publicMeeting?: Maybe<PublicMeeting>;
  recurrence: Array<Scalars['String']['output']>;
  recurrencesV2?: Maybe<Array<RecurrenceV3>>;
  reminders?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  status: RoutineStatusType;
  statusChanged?: Maybe<Scalars['DateTime']['output']>;
  type: RoutineType;
  wellnessGoals: Array<WellnessGoal>;
};


export type RoutineClosestStartsAtArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']['input']>;
  timeZone: Scalars['String']['input'];
};


export type RoutineRecurrencesV2Args = {
  fromDate: Scalars['DateTime']['input'];
  toDate: Scalars['DateTime']['input'];
};

export type RoutineCategoryGroup = {
  __typename?: 'RoutineCategoryGroup';
  categories: Array<Category>;
  id: Scalars['Int']['output'];
  name: CategoryGroup;
  position?: Maybe<Scalars['Int']['output']>;
  requirement: RoutineCreationRequirement;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type RoutineCreationInfo = {
  __typename?: 'RoutineCreationInfo';
  createdBy: Scalars['String']['output'];
};

export enum RoutineCreationRequirement {
  Location = 'Location',
  LocationOrUserLink = 'LocationOrUserLink',
  None = 'None',
  ProviderLink = 'ProviderLink'
}

export type RoutineLocationInput = {
  formattedAddress: Scalars['String']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  timeZone: Scalars['String']['input'];
};

export enum RoutineOrigin {
  DefaultDay = 'DefaultDay',
  Routinify = 'Routinify',
  Self = 'Self',
  TreatmentCenter = 'TreatmentCenter'
}

export type RoutineQueryInput = {
  memberId?: InputMaybe<Scalars['Int']['input']>;
  routineId: Scalars['Int']['input'];
};

export type RoutineResponse = Routine | ValidationErrors;

export enum RoutineStatusType {
  Accepted = 'Accepted',
  Mandatory = 'Mandatory',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

export enum RoutineType {
  SelfCare = 'SelfCare',
  Support = 'Support'
}

export type RoutinesInput = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};

export enum SudConcernsInput {
  No = 'No',
  PreferNotToAnswer = 'PreferNotToAnswer',
  Yes = 'Yes'
}

export type SudQuestionInput = {
  response: Scalars['Int']['input'];
};

export type SudQuestionResponse = {
  __typename?: 'SUDQuestionResponse';
  id: Scalars['Int']['output'];
  memberId: Scalars['Int']['output'];
  response: Scalars['Int']['output'];
};

export type SendMessageToMembersInput = {
  memberIds: Array<Scalars['Int']['input']>;
  message: Scalars['String']['input'];
};

export type SendMessageToMembersResponse = MutationResponse & {
  __typename?: 'SendMessageToMembersResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type Session = {
  __typename?: 'Session';
  jwt: Scalars['ID']['output'];
  sendbirdToken?: Maybe<SessionToken>;
};

export type SessionResponse = Session | ValidationErrors;

export type SessionToken = {
  __typename?: 'SessionToken';
  expiresAt: Scalars['Float']['output'];
  token: Scalars['String']['output'];
};

export type SetAccountStatusInput = {
  accountStatus: AccountStatus;
  memberId: Scalars['Int']['input'];
};

export type SetAccountStatusMutationResponse = MutationResponse & {
  __typename?: 'SetAccountStatusMutationResponse';
  code: Scalars['Int']['output'];
  member?: Maybe<Member>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type SetRewardsEnabledInput = {
  id: Scalars['Int']['input'];
  rewardsEnabled: Scalars['Boolean']['input'];
};

export type SetRewardsEnabledResponse = {
  __typename?: 'SetRewardsEnabledResponse';
  memberV2: Member;
};

export type SetRoutineStatusInput = {
  newStatus: RoutineStatusType;
  routineId: Scalars['Int']['input'];
};

export type SetRoutineStatusResponse = {
  __typename?: 'SetRoutineStatusResponse';
  _?: Maybe<Scalars['Boolean']['output']>;
  routine: Routine;
};

export type SignInDashboardUserInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type SignInDashboardUserResponse = {
  __typename?: 'SignInDashboardUserResponse';
  session: Session;
};

export type SignInDashboardUserResponseV2 = MutationResponse & {
  __typename?: 'SignInDashboardUserResponseV2';
  code: Scalars['Int']['output'];
  dashboardUser?: Maybe<DashboardUser>;
  message: Scalars['String']['output'];
  session?: Maybe<Session>;
  success: Scalars['Boolean']['output'];
};

export type SignInInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phoneNumberAssociationCode?: InputMaybe<Scalars['String']['input']>;
};

export type SignInResponse = {
  __typename?: 'SignInResponse';
  session: Session;
};

export type SignInResponseV2 = MutationResponse & {
  __typename?: 'SignInResponseV2';
  code: Scalars['Int']['output'];
  message?: Maybe<Scalars['String']['output']>;
  session?: Maybe<Session>;
  success: Scalars['Boolean']['output'];
};

export type SignInWithPhoneNumberInput = {
  code: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};

export type SignInWithPhoneNumberResponse = {
  __typename?: 'SignInWithPhoneNumberResponse';
  phoneNumberAssociationCode?: Maybe<Scalars['String']['output']>;
  session?: Maybe<Session>;
};

export type SignOutInput = {
  deviceId?: InputMaybe<Scalars['String']['input']>;
};

export type SignOutResponse = {
  __typename?: 'SignOutResponse';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type SmartRoutine = {
  __typename?: 'SmartRoutine';
  anytime?: Maybe<Scalars['Boolean']['output']>;
  category?: Maybe<Category>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  openaiId: Scalars['String']['output'];
  recurrence: Array<Scalars['String']['output']>;
};

export type SmartRoutineFeedbackInput = {
  feedback: Scalars['String']['input'];
  openaiId: Scalars['String']['input'];
};

export type SmartRoutineFeedbackResponse = {
  __typename?: 'SmartRoutineFeedbackResponse';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type SmartRoutineQueryInput = {
  routineDescription: Scalars['String']['input'];
};

export enum SocialSupportSystemResponse {
  No = 'No',
  NotSure = 'NotSure',
  Yes = 'Yes'
}

export type StandardSurveyInput = {
  biggestChallenge?: InputMaybe<Scalars['String']['input']>;
  feedbackHelps?: InputMaybe<Scalars['String']['input']>;
  hasHelpedNPS: Scalars['String']['input'];
  howSpentRewards?: InputMaybe<Array<Scalars['String']['input']>>;
  mostHelpfulForRecovery?: InputMaybe<Scalars['String']['input']>;
  wouldRecommendNPS: Scalars['String']['input'];
};

export type StandardSurveyV2 = {
  __typename?: 'StandardSurveyV2';
  biggestChallenge?: Maybe<Scalars['String']['output']>;
  dateCompleted: Scalars['DateTime']['output'];
  days: Scalars['Int']['output'];
  earnedReward?: Maybe<EarnedReward>;
  feedbackHelps?: Maybe<Scalars['String']['output']>;
  hasHelpedNPS: Scalars['String']['output'];
  howSpentRewards?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  /** @deprecated removed from standard survey */
  lastDaysAttendedMeetings?: Maybe<Scalars['Int']['output']>;
  mostHelpfulForRecovery?: Maybe<Scalars['String']['output']>;
  wouldRecommendNPS: Scalars['String']['output'];
};

export type Stats = {
  __typename?: 'Stats';
  /** @deprecated legacy rewards programs are deprecated */
  favoriteRoutine: Scalars['String']['output'];
  /** @deprecated legacy rewards programs are deprecated */
  routinesAttended: Scalars['Int']['output'];
};

export type Strength = {
  __typename?: 'Strength';
  id: Scalars['Int']['output'];
  prompt: Scalars['String']['output'];
  responses: Array<StrengthResponse>;
};

export type StrengthInput = {
  promptId: Scalars['Int']['input'];
  responses: Array<Scalars['String']['input']>;
};

export type StrengthResponse = {
  __typename?: 'StrengthResponse';
  id: Scalars['Int']['output'];
  response: Scalars['String']['output'];
};

export type SubscriptionMutationResponse = MutationResponse & {
  __typename?: 'SubscriptionMutationResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  subscription?: Maybe<PaidSubscription>;
  success: Scalars['Boolean']['output'];
};

export enum SubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Trialing = 'trialing',
  Unpaid = 'unpaid'
}

export type SupportedHealthPlan = {
  __typename?: 'SupportedHealthPlan';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type SupportivePerson = {
  __typename?: 'SupportivePerson';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  relationship?: Maybe<Scalars['String']['output']>;
};

export type SupportivePersonInput = {
  name: Scalars['String']['input'];
  relationship?: InputMaybe<Scalars['String']['input']>;
};

export type TimeTravelInput = {
  timeTravel?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TreatmentProvider = {
  __typename?: 'TreatmentProvider';
  contract: Contract;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type TreatmentProviderMutationResponse = MutationResponse & {
  __typename?: 'TreatmentProviderMutationResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  treatmentProvider?: Maybe<TreatmentProvider>;
};

export type UpdateAwayMessageInput = {
  awayMessage: AwayMessageInput;
  peerId?: InputMaybe<Scalars['Int']['input']>;
  timeZone: Scalars['String']['input'];
  workHours: WorkHoursInput;
};

export type UpdateBehavioralHealthProfileV2Input = {
  concerns?: InputMaybe<Array<ConcernInput>>;
  daysInterfered?: InputMaybe<Scalars['Int']['input']>;
  experiencingConcerns?: InputMaybe<BhConcernsInput>;
};

export type UpdateBrandKeysInput = {
  id: Scalars['Int']['input'];
  tangoBrandKeys: Array<Scalars['String']['input']>;
};

export type UpdateDashboardUserInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  calendarLink?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDashboardUserResponse = MutationResponse & {
  __typename?: 'UpdateDashboardUserResponse';
  code: Scalars['Int']['output'];
  dashboardUser?: Maybe<DashboardUserResponse>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type UpdateEligibilityInput = {
  birthDate: Scalars['Date']['input'];
  firstName: Scalars['String']['input'];
  healthPlanId?: InputMaybe<Scalars['Int']['input']>;
  insuranceMemberId?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  memberId: Scalars['Int']['input'];
};

export type UpdateEngagedInput = {
  engaged: Scalars['Boolean']['input'];
  memberId: Scalars['Int']['input'];
};

export type UpdateEngagedResponse = {
  __typename?: 'UpdateEngagedResponse';
  member: Member;
};

export type UpdateFeatureOverridesInput = {
  key: Scalars['String']['input'];
  memberIds: Array<Scalars['Int']['input']>;
};

export type UpdateFeatureOverridesResponse = {
  __typename?: 'UpdateFeatureOverridesResponse';
  key: Scalars['String']['output'];
  memberIds: Array<Scalars['Int']['output']>;
};

export type UpdateMaxPotentialRewardsInput = {
  maxPotentialRewards: Scalars['Int']['input'];
  memberId: Scalars['Int']['input'];
};

export type UpdateMemberTreatmentProviderInput = {
  memberId: Scalars['Int']['input'];
  treatmentProviderId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateMemberV3Input = {
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  languageTag?: InputMaybe<Scalars['String']['input']>;
  lastKnownTimeZone?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  preferredName?: InputMaybe<Scalars['String']['input']>;
  progressDate?: InputMaybe<Scalars['Date']['input']>;
  pronouns?: InputMaybe<Array<MemberPronounInput>>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMembersBenefitsInput = {
  memberIds: Array<Scalars['Int']['input']>;
  messagingDaysAdded?: InputMaybe<Scalars['Int']['input']>;
  rewardsAmountAdded?: InputMaybe<Scalars['Int']['input']>;
  rewardsDaysAdded?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateNotesInput = {
  csNotes?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['Int']['input'];
  mesNotes?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePeerProfileInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  calendarLink?: InputMaybe<Scalars['String']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePhoneNumberInput = {
  newPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['Int']['input'];
};

export type UpdatePhoneNumberResponse = {
  __typename?: 'UpdatePhoneNumberResponse';
  memberV2: Member;
};

export type UpdateProblematicSubstancesV2Input = {
  experiencingSudConcerns: SudConcernsInput;
  problematicSubstances?: InputMaybe<Array<MemberSubstanceInput>>;
};

export type UpdateRewardsCodeInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  redemptionLimit?: InputMaybe<Scalars['Int']['input']>;
  startingBalance?: InputMaybe<Scalars['Int']['input']>;
  treatmentProviderId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateRewardsCodesInput = {
  ids: Array<Scalars['Int']['input']>;
  treatmentProvider?: InputMaybe<UpdateRewardsCodesTreatmentProviderInput>;
};

export type UpdateRewardsCodesTreatmentProviderInput = {
  contractId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};

export type UpdateRoutineMutationResponse = MutationResponse & {
  __typename?: 'UpdateRoutineMutationResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  routine?: Maybe<RoutineResponse>;
  success: Scalars['Boolean']['output'];
};

export type UpdateRoutineV3Input = {
  anytime: Scalars['Boolean']['input'];
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  changeFuture: Scalars['Boolean']['input'];
  id: Scalars['Int']['input'];
  link?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<RoutineLocationInput>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  originalStartsAt: Scalars['DateTime']['input'];
  publicMeetingId?: InputMaybe<Scalars['Int']['input']>;
  recurrence: Array<Scalars['String']['input']>;
  reminders?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  wellnessGoalIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UpdateSubscriptionPriceInput = {
  priceId: Scalars['String']['input'];
};

export type UpdateTimeTravelResponse = MutationResponse & {
  __typename?: 'UpdateTimeTravelResponse';
  code: Scalars['Int']['output'];
  member?: Maybe<Member>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type UpdateTreatmentProviderInput = {
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type UpgradeAccountInput = {
  birthDate: Scalars['Date']['input'];
  firstName: Scalars['String']['input'];
  healthPlanId?: InputMaybe<Scalars['Int']['input']>;
  insuranceMemberId?: InputMaybe<Scalars['String']['input']>;
  insuranceProvider?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  preferredName?: InputMaybe<Scalars['String']['input']>;
  rewardsCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  wecWorksConnection?: InputMaybe<WecWorksConnectionType>;
};

export type UpgradeAccountMutationResponse = MutationResponse & {
  __typename?: 'UpgradeAccountMutationResponse';
  code: Scalars['Int']['output'];
  ineligibleReasons?: Maybe<Array<Scalars['String']['output']>>;
  member?: Maybe<MemberResponse>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type UpgradeByAdminInput = {
  contractId: Scalars['Int']['input'];
  memberId: Scalars['Int']['input'];
};

export type UpsertFeatureOverrideInput = {
  key: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};

export type ValidateActivationCodeResponse = MutationResponse & {
  __typename?: 'ValidateActivationCodeResponse';
  activationCode?: Maybe<RewardsCode>;
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ValidateOnboardingCodeInput = {
  code: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type ValidateOnboardingCodeMutationResponse = MutationResponse & {
  __typename?: 'ValidateOnboardingCodeMutationResponse';
  code: Scalars['Int']['output'];
  jwt?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type ValidateOnboardingCodeResponse = {
  __typename?: 'ValidateOnboardingCodeResponse';
  jwt: Scalars['String']['output'];
};

export type ValidatePromoCodeInput = {
  promoCode: Scalars['String']['input'];
};

export type ValidationError = {
  __typename?: 'ValidationError';
  errors: Array<Scalars['String']['output']>;
  field: Scalars['String']['output'];
};

export type ValidationErrors = {
  __typename?: 'ValidationErrors';
  validationErrors: Array<ValidationError>;
};

export enum ValueType {
  FixedValue = 'FIXED_VALUE',
  VariableValue = 'VARIABLE_VALUE'
}

export type VariableItem = Item & {
  __typename?: 'VariableItem';
  maxValue: Scalars['Int']['output'];
  minValue: Scalars['Int']['output'];
  rewardName: Scalars['String']['output'];
  type: ValueType;
  utid: Scalars['String']['output'];
};

export type VerifyChangeCredentialInput = {
  code: Scalars['String']['input'];
};

export type VerifyChangeCredentialMutationResponse = MutationResponse & {
  __typename?: 'VerifyChangeCredentialMutationResponse';
  code: Scalars['Int']['output'];
  member?: Maybe<Member>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type VerifyChangeCredentialResponse = {
  __typename?: 'VerifyChangeCredentialResponse';
  memberV2: Member;
};

export type VerifyEmailInput = {
  code: Scalars['String']['input'];
};

export type VerifyEmailMutationResponse = MutationResponse & {
  __typename?: 'VerifyEmailMutationResponse';
  code: Scalars['Int']['output'];
  member?: Maybe<Member>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export enum WecWorksConnectionType {
  Dependent = 'Dependent',
  Employee = 'Employee',
  PreferNotToAnswer = 'PreferNotToAnswer',
  Spouse = 'Spouse'
}

export type WellnessGoal = {
  __typename?: 'WellnessGoal';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dashboardUser?: Maybe<DashboardUser>;
  description?: Maybe<Scalars['String']['output']>;
  displayInApp: Scalars['Boolean']['output'];
  domain?: Maybe<WellnessGoalDomain>;
  id: Scalars['Int']['output'];
  member: Member;
  priorityLevel?: Maybe<WellnessGoalPriorityLevel>;
  routines: Array<Routine>;
  status: WellnessGoalStatus;
  title: Scalars['String']['output'];
};

export enum WellnessGoalDomain {
  DailyLivingAndRoutines = 'DailyLivingAndRoutines',
  Education = 'Education',
  Employment = 'Employment',
  Finances = 'Finances',
  Housing = 'Housing',
  Legal = 'Legal',
  MentalHealth = 'MentalHealth',
  Other = 'Other',
  PhysicalHealth = 'PhysicalHealth',
  Relationships = 'Relationships',
  Safety = 'Safety',
  Spirituality = 'Spirituality'
}

export type WellnessGoalInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayInApp?: InputMaybe<Scalars['Boolean']['input']>;
  domain?: InputMaybe<WellnessGoalDomain>;
  goal?: InputMaybe<Scalars['String']['input']>;
  priorityLevel?: InputMaybe<WellnessGoalPriorityLevel>;
  status: WellnessGoalStatus;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type WellnessGoalMutationResponse = MutationResponse & {
  __typename?: 'WellnessGoalMutationResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  wellnessGoal?: Maybe<WellnessGoalResponse>;
};

export enum WellnessGoalPriorityLevel {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium'
}

export type WellnessGoalResponse = ValidationErrors | WellnessGoal;

export enum WellnessGoalStatus {
  Complete = 'Complete',
  InProgress = 'InProgress',
  Paused = 'Paused'
}

export type WorkHours = {
  __typename?: 'WorkHours';
  endTime: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  recurrence: Array<Scalars['String']['output']>;
  startTime: Scalars['DateTime']['output'];
};

export type WorkHoursInput = {
  endTime: Scalars['DateTime']['input'];
  recurrence: Array<Scalars['String']['input']>;
  startTime: Scalars['DateTime']['input'];
};

export type Barc10Answer = {
  __typename?: 'barc10Answer';
  question?: Maybe<Barc10Question>;
  response: Scalars['Int']['output'];
};

export type TestQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type TestQueryQuery = { __typename?: 'Query', groups: Array<{ __typename: 'RoutineCategoryGroup' }> };

export type RequestSigninVerificationCodeMutationVariables = Exact<{
  input: RequestSmsVerificationCodeInput;
}>;


export type RequestSigninVerificationCodeMutation = { __typename?: 'Mutation', requestSigninVerificationCode: { __typename: 'RequestVerificationResponse' } };

export type SignInV2MutationVariables = Exact<{
  input: SignInWithPhoneNumberInput;
}>;


export type SignInV2Mutation = { __typename?: 'Mutation', signInV2: { __typename?: 'SignInResponseV2', success: boolean, message?: string | null, code: number, session?: { __typename?: 'Session', jwt: string } | null } };

export type JoinMeetingMutationMutationVariables = Exact<{
  input: JoinMeetingInput;
}>;


export type JoinMeetingMutationMutation = { __typename?: 'Mutation', joinMeeting: { __typename?: 'JoinMeetingResponse', success: boolean } };

export type PublicMeetingsQueryVariables = Exact<{ [key: string]: never; }>;


export type PublicMeetingsQuery = { __typename?: 'Query', general: Array<{ __typename?: 'PublicMeeting', id: number, duration: number, header: string, url: string, timeZone: string, title: string, nextStartsAt?: DateTime | null }>, specialized: Array<{ __typename?: 'PublicMeeting', id: number, duration: number, header: string, url: string, timeZone: string, title: string, nextStartsAt?: DateTime | null }> };


export const TestQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"TestQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"groups"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}}]}}]}}]} as unknown as DocumentNode<TestQueryQuery, TestQueryQueryVariables>;
export const RequestSigninVerificationCodeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RequestSigninVerificationCode"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"RequestSMSVerificationCodeInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"requestSigninVerificationCode"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}}]}}]}}]} as unknown as DocumentNode<RequestSigninVerificationCodeMutation, RequestSigninVerificationCodeMutationVariables>;
export const SignInV2Document = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SignInV2"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SignInWithPhoneNumberInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"signInV2"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"session"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"jwt"}}]}}]}}]}}]} as unknown as DocumentNode<SignInV2Mutation, SignInV2MutationVariables>;
export const JoinMeetingMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"JoinMeetingMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"JoinMeetingInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"joinMeeting"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode<JoinMeetingMutationMutation, JoinMeetingMutationMutationVariables>;
export const PublicMeetingsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"PublicMeetings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"general"},"name":{"kind":"Name","value":"publicMeetings"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"type"},"value":{"kind":"EnumValue","value":"General"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"duration"}},{"kind":"Field","name":{"kind":"Name","value":"header"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"url"}},{"kind":"Field","name":{"kind":"Name","value":"timeZone"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"nextStartsAt"}}]}},{"kind":"Field","alias":{"kind":"Name","value":"specialized"},"name":{"kind":"Name","value":"publicMeetings"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"type"},"value":{"kind":"EnumValue","value":"Specialized"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"duration"}},{"kind":"Field","name":{"kind":"Name","value":"header"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"url"}},{"kind":"Field","name":{"kind":"Name","value":"timeZone"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"nextStartsAt"}}]}}]}}]} as unknown as DocumentNode<PublicMeetingsQuery, PublicMeetingsQueryVariables>;