import { from } from '@apollo/client'

import { context } from './context'
import { customScalars } from './customScalars'
import { errorLogger } from './errorHandler'
import { httpLink } from './http'
import { subscribable } from './subscribable'

export const link = from([
  subscribable,
  errorLogger,
  context,
  customScalars,
  httpLink,
])
