import { Context } from '@apollo/client'
import { ContextSetter, setContext } from '@apollo/client/link/context'
import { Settings, Zone } from 'luxon'

export const contextSetter: ContextSetter = (_, context: Context) => {
  return {
    ...context,
    headers: {
      ...context.headers,
      'x-time-zone': (Settings.defaultZone as Zone).name,
    },
  }
}

export const context = setContext(contextSetter)
