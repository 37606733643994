import { createTheme } from '@mui/material'
import { palette } from './palette'

export const theme = createTheme({
  palette,
  typography: {
    button: { textTransform: 'none' },
    subtitle1: {
      fontSize: '1.2rem',
    },
  },
})
