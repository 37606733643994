import { ApolloClient, InMemoryCache } from '@apollo/client'

import { link } from './link'

export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      RecurrenceV2: { keyFields: ['uuid'] },
    },
  }),
  link,
  connectToDevTools: true,
  name: 'web',
  version: import.meta.env.VITE_RELEASE_ID,
})
