import { useQuery } from '@apollo/client'
import { graphql } from 'src/gql'
import { Box, Container, Link, Typography } from '@mui/material'
import { SignInModal } from 'src/components/SignInModal'
import { useState } from 'react'
import { PublicMeetingsQuery } from 'src/gql/graphql'
import { PublicMeetingsList } from 'src/components/PublicMeetingList'

export const PublicMeetings = () => {
  const { data, loading } = useQuery(
    graphql(`
      query PublicMeetings {
        general: publicMeetings(type: General) {
          id
          duration
          header
          id
          url
          timeZone
          title
          nextStartsAt
        }
        specialized: publicMeetings(type: Specialized) {
          id
          duration
          header
          id
          url
          timeZone
          title
          nextStartsAt
        }
      }
    `),
  )

  const [selectedMeeting, selectMeeting] = useState<
    PublicMeetingsQuery['general'][0] | null
  >(null)

  return (
    <Container maxWidth="xl">
      <SignInModal
        selectedMeeting={selectedMeeting}
        clearMeeting={() => selectMeeting(null)}
      />
      <Box my={t => t.spacing(6)}>
        <Typography variant="h2" textAlign="center" mb={t => t.spacing(4)}>
          WEconnect Daily Meetings
        </Typography>
        <Box display="flex" justifyContent="center">
          <Typography
            variant="subtitle1"
            textAlign="justify"
            maxWidth="40rem"
            mb={t => t.spacing(4)}>
            Once the meeting goes live, the "Join" button will become active.
            Clicking "Join" will bring you to the Zoom link for that meeting. If
            you want to access the meetings through the mobile app, you can
            download it{' '}
            <Link href="https://www.weconnecthealth.io/free" target="_blank">
              here
            </Link>
            .
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
        <Typography
            variant="body1"
            textAlign="center"
            maxWidth="40rem"
            style={{ whiteSpace: 'pre-line' }}
            mb={t => t.spacing(4)}>
            Call in to join: {"\n"}
            For General Meetings:{"\n"}
            +1 253-205-0468{"\n"}
            Meeting ID:  4658475493{"\n\n"}
            For Specialty Meetings:{"\n"}
            +1 253 205 0468{"\n"}
            Meeting ID: 5806735585{"\n\n"}
            Note: if you are calling from outside of the US, you can find the number to use{' '}
            <Link href="https://weconnectrecovery.zoom.us/u/kSGUCNd1" target="_blank">
              here
            </Link>
            .
          </Typography>
        </Box>
        <PublicMeetingsList
          meetings={data?.general ?? []}
          loading={loading}
          selectMeeting={selectMeeting}
        />
      </Box>
      <Box mt={t => t.spacing(20)}>
        <Typography variant="h2" textAlign="center" mb={t => t.spacing(4)}>
          WEconnect Specialty Meetings
        </Typography>
        <PublicMeetingsList
          meetings={data?.specialized ?? []}
          variant="specialized"
          loading={loading}
          selectMeeting={selectMeeting}
        />
      </Box>
    </Container>
  )
}
